import React from "react";
import { Tooltip } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

const MATruncatedText = ({ text }) => {
  const truncationStyles = {
    fontSize: "1rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "150px", // Adjust as needed
  };

  return (
    <VuiBox>
      <Tooltip title={text} arrow>
        <VuiTypography color="white" sx={truncationStyles}>
          {text}
        </VuiTypography>
      </Tooltip>
    </VuiBox>
  );
};

export default MATruncatedText;
