import React from "react";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { Security as SecurityIcon } from "@mui/icons-material";
import { StyledSection } from "../../Styles";

const LoginNotifications = ({ loginNotifications, onChange }) => {
  return (
    <StyledSection>
      <Box display="flex" alignItems="center" mb={2}>
        <SecurityIcon sx={{ mr: 1, color: "primary.main" }} />
        <Typography variant="h6">Login Notifications</Typography>
      </Box>
      <FormControlLabel
        control={
          <Switch
            checked={loginNotifications}
            onChange={onChange}
            name="loginNotifications"
            color="primary"
          />
        }
        label="Receive notifications for new logins"
      />
      <Typography variant="body2" color="text.secondary">
        Get alerts whenever your account is accessed from a new device.
      </Typography>
    </StyledSection>
  );
};

export default LoginNotifications;
