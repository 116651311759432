import { Grid, Card, CircularProgress, Typography } from "@mui/material";
import VuiBox from "components/VuiBox";
import React, { useCallback, useEffect, useState } from "react";
import Actions from "./Actions/Actions";
import Charts from "./Charts/Charts";
import useApi from "shared/hooks/UseApi";
import { getAnalytics1Stats } from "shared/services/ApiService/ApiService";

function Stats() {
  const [actionsData, setActionsData] = useState([]);
  const [chartsData, setChartsData] = useState([]);
  const { loading, error, callApi } = useApi(getAnalytics1Stats);

  const fetchAnalytics1Stats = useCallback(async () => {
    try {
      const response = await callApi();

      if (response?.status === 200 && response?.data?.[0]?.analytics1stats) {
        const { priority_summary: priority, AnalyticsChartsData: charts } =
          response.data[0].analytics1stats;
        // console.log(charts);
        setActionsData([
          { title: "Total Action Items", value: priority?.total_action_items ?? 0 },
          { title: "High Priority Actions", value: priority?.high_priority_items ?? 0 },
          { title: "Medium Priority Actions", value: priority?.medium_priority_items ?? 0 },
          { title: "Low Priority Actions", value: priority?.low_priority_items ?? 0 },
        ]);

        setChartsData([
          {
            title: "Total Action Items",
            data: [
              charts?.completed_items ?? 0,
              charts?.in_progress_items ?? 0,
              charts?.not_started_items ?? 0,
            ],
            labels: ["Completed", "In Progress", "Not Started"],
          },
          {
            title: "High Priority Actions",
            data: [
              charts?.high_priority?.completed ?? 0,
              charts?.high_priority?.in_progress ?? 0,
              charts?.high_priority?.not_started ?? 0,
            ],
            labels: ["Completed", "In Progress", "Not Started"],
          },
          {
            title: "Medium Priority Actions",
            data: [
              charts?.medium_priority?.completed ?? 0,
              charts?.medium_priority?.in_progress ?? 0,
              charts?.medium_priority?.not_started ?? 0,
            ],
            labels: ["Completed", "In Progress", "Not Started"],
          },
          {
            title: "Low Priority Actions",
            data: [
              charts?.low_priority?.completed ?? 0,
              charts?.low_priority?.in_progress ?? 0,
              charts?.low_priority?.not_started ?? 0,
            ],
            labels: ["Completed", "In Progress", "Not Started"],
          },
        ]);
      }
    } catch (err) {
      console.error("Error fetching analytics stats:", err);
    }
  }, [callApi]);

  useEffect(() => {
    fetchAnalytics1Stats();
  }, [fetchAnalytics1Stats]);

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <VuiBox p={2}>
            <Actions actionsData={actionsData} loading={loading} error={error} />
          </VuiBox>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <VuiBox p={2}>
            <Charts chartsData={chartsData} loading={loading} error={error} />
          </VuiBox>
        </Card>
      </Grid>
    </>
  );
}

export default Stats;
