import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// Initialize state from cookies, falling back to null if not available
const initialState = {
  token: Cookies.get("access_token") || null,
  authProvider: Cookies.get("authProvider") || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthProvider: (state, action) => {
      state.authProvider = action.payload; // Set the auth provider directly
      Cookies.set("authProvider", action.payload, {
        expires: 7,
        secure: true,
        sameSite: "Strict",
      }); // Set auth provider in cookie
    },
    removeAuthProvider: (state) => {
      state.authProvider = null; // Clear the auth provider
      Cookies.remove("authProvider"); // Remove auth provider from cookie
    },
    setToken: (state, action) => {
      state.token = action.payload; // Set the token directly
      Cookies.set("access_token", action.payload, {
        expires: 7,
        secure: true,
        sameSite: "Strict",
      }); // Set token in cookie
    },
    removeToken: (state) => {
      state.token = null; // Clear the token
      Cookies.remove("access_token"); // Remove token from cookie
      Cookies.remove("refresh_token"); // Remove refresh token from cookie
    },
  },
});

// Export actions for use in components
export const { setAuthProvider, removeAuthProvider, setToken, removeToken } = authSlice.actions;

// Export the reducer to be used in the store
export default authSlice.reducer;
