import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Alert,
} from "@mui/material";
import { ExpandMore, People } from "@mui/icons-material";
import VuiBox from "components/VuiBox";

const UserManagement = ({ userRole, setUserRole }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="user-management-content"
        id="user-management-header"
      >
        <VuiBox sx={{ display: "flex", alignItems: "center" }}>
          <People sx={{ mr: 2 }} />
          <Typography variant="h6">User Management</Typography>
        </VuiBox>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Assign Role</InputLabel>
              <Select
                value={userRole}
                onChange={(e) => setUserRole(e.target.value)}
                label="Assign Role"
              >
                <MenuItem value="super_admin">Super Admin</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="manager">Manager</MenuItem>
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="viewer">Viewer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info" sx={{ mt: 2 }}>
              Super Admin has full system access. Managers can manage team members and view
              analytics.
            </Alert>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default UserManagement;
