import React, { useState, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const DataGridComponent = ({ rows, columns, checkboxSelection = true, autoHeight = true }) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelection = useCallback((newSelection) => setSelectedRows(newSelection), []);

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{ pagination: { paginationModel } }}
      pageSizeOptions={[5, 10]}
      onPaginationModelChange={setPaginationModel}
      checkboxSelection={checkboxSelection}
      disableRowSelectionOnClick
      autoHeight={autoHeight}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      onRowSelectionModelChange={handleRowSelection}
      getRowClassName={(params) => (params.row.isSubRow ? "MuiDataGrid-row--subRow" : "")}
      getRowId={(row) => row.id}
      sx={{
        "& .MuiTablePagination-input": {
          width: "fit-content !important",
        },
        "& .MuiDataGrid-scrollbar": {
          overflow: "auto",
          "&::-webkit-scrollbar": {
            height: "0.7rem",
            width: "max-content",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "rgba(13, 17, 38, 0.6)", // Dark blue-ish background
            borderRadius: "1rem",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#2563eb", // Primary blue color
            borderRadius: "1rem",
            "&:hover": {
              backgroundColor: "#1d4ed8", // Darker blue on hover
            },
          },
        },
      }}
    />
  );
};

export default DataGridComponent;
