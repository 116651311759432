import React, { useState, useRef } from "react";
import { Popper, ClickAwayListener } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const MADropdown = ({ statuses, selectedStatus, onStatusChange, isEditable }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  // const statuses = ["Not Started", "In Progress", "Completed", "Delayed"];

  const handleToggle = () => {
    if (isEditable) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleStatusSelect = (status) => {
    onStatusChange(status);
    setOpen(false);
  };

  return (
    <VuiBox
      ref={anchorRef}
      onClick={handleToggle}
      sx={{
        cursor: isEditable ? "pointer" : "default",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <VuiTypography variant="button" color="dark" fontWeight="medium">
        {selectedStatus}
      </VuiTypography>
      {isEditable && (
        <>
          {open ? (
            <KeyboardArrowUp fontSize="small" color="dark" sx={{ ml: 0.5 }} />
          ) : (
            <KeyboardArrowDown fontSize="small" color="dark" sx={{ ml: 0.5 }} />
          )}
        </>
      )}
      {open && isEditable && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          style={{ zIndex: 1300 }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <VuiBox
              sx={{
                maxHeight: 48 * 4.5,
                width: "20ch",
                borderRadius: "12px",
                backgroundColor: "#291fed",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
              }}
            >
              {statuses.map((status) => (
                <VuiTypography
                  key={status}
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  onClick={() => handleStatusSelect(status)}
                  sx={{
                    py: 1,
                    px: 2,
                    display: "block",
                    cursor: "pointer",
                    "&:hover": {
                      borderRadius: "12px",
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                    },
                  }}
                >
                  {status}
                </VuiTypography>
              ))}
            </VuiBox>
          </ClickAwayListener>
        </Popper>
      )}
    </VuiBox>
  );
};

export default MADropdown;
