import React, { useState, useRef } from "react";
import { Popper, ClickAwayListener } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import useAuth from "shared/hooks/UseAuth";

const MAAttendeeDropdown = ({ attendees }) => {
  const [open, setOpen] = useState(false);
  const { authProvider } = useAuth();
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <VuiBox
      ref={anchorRef}
      onClick={handleToggle}
      sx={{
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <VuiTypography variant="button" color="white" fontWeight="regular">
        Attendees ({attendees.length})
      </VuiTypography>
      {open ? (
        <KeyboardArrowUp fontSize="small" color="white" sx={{ ml: 0.5 }} />
      ) : (
        <KeyboardArrowDown fontSize="small" color="white" sx={{ ml: 0.5 }} />
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        style={{ zIndex: 1300 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <VuiBox
            sx={{
              maxHeight: 48 * 4.5,
              width: "20ch",
              borderRadius: "12px", // Rounded corners for dropdown
              backgroundColor: "#291fed", // Custom background color
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
            }}
          >
            {attendees.map((attendee, index) => (
              <VuiTypography
                key={index}
                variant="button"
                color="white"
                fontWeight="regular"
                sx={{
                  py: 1,
                  px: 2,
                  display: "block",
                  "&:hover": {
                    borderRadius: "12px", // Rounded corners for dropdown
                    backgroundColor: "rgba(255, 255, 255, 0.2)", // Hover color for menu items
                  },
                }}
              >
                {authProvider === "outlook" ? attendee : attendee?.email}
              </VuiTypography>
            ))}
          </VuiBox>
        </ClickAwayListener>
      </Popper>
    </VuiBox>
  );
};

export default MAAttendeeDropdown;
