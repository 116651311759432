import React from "react";
import { Card, Grid } from "@mui/material"; // Using Material-UI Grid
import VuiBox from "components/VuiBox"; // Ensure this path is correct
import VuiTypography from "components/VuiTypography"; // Ensure this path is correct
import CardContent from "@mui/material/CardContent";
import VuiButton from "components/VuiButton"; // Ensure this path is correct

function FilterCard() {
  const buttonLabels = [
    "Action Items",
    "Follow Up",
    "Deadline",
    "Budget",
    "Resources",
    "Priority",
    "Feedback",
    "Schedule",
  ];

  // Define an array of unique colors for each button
  const buttonColors = [
    "primary",
    "info",
    "success",
    "error",
    "warning",
    "info",
    "inherit",
    "default", // Add more colors as needed
  ];

  return (
    <Grid container spacing={2}>
      {/* Header Section */}
      <Grid item xs={12}>
        <VuiBox>
          <VuiTypography variant="h6" color="white">
            Filter Words Used During Meeting
          </VuiTypography>
        </VuiBox>
      </Grid>

      {/* Button Row */}
      <Grid item xs={12}>
        <VuiBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
            flexWrap: "wrap",
          }}
        >
          {buttonLabels.map((label, index) => (
            <VuiButton
              key={index}
              variant="outlined"
              color={buttonColors[index % buttonColors.length]} // Cycle through the button colors
              sx={{ flex: "1 1 20%", margin: "5px" }} // Responsive button sizing with margin
            >
              {label}
            </VuiButton>
          ))}
        </VuiBox>
      </Grid>

      {/* Main Card Section */}
      <Grid item xs={12}>
        <VuiBox sx={{ backgroundColor: "#0f1635", borderRadius: "1rem", padding: 2 }}>
          <Grid container spacing={2}>
            {/* Main Title */}
            <Grid item xs={12}>
              <VuiTypography variant="h4" color="white" fontWeight="bold">
                Filter Word Analytics
              </VuiTypography>
            </Grid>

            {/* Left Section: Too Repeated Intents */}
            <Grid item xs={12} sm={6} lg={6}>
              <VuiTypography variant="h6" color="white" fontWeight="bold">
                Too Repeated Intents (10)
              </VuiTypography>
              {/* Individual Interaction Cards */}
              {[
                { title: "Action items", value: "Repeated 45 times" },
                { title: "Budget Discussion", value: "Repeated 15 times" },
                { title: "Client Feedback", value: "Repeated 25 times" },
                { title: "Problem Solving", value: "Repeated 35 times" },
              ].map((item, index) => (
                <Card key={index} sx={{ backgroundColor: "#1e1e1e", color: "white", marginTop: 1 }}>
                  <CardContent>
                    <VuiTypography variant="h6" color="white">
                      {item.title}
                    </VuiTypography>
                    <VuiBox flexWrap="noWrap" justifyContent="space-around" display="flex">
                      <VuiTypography variant="h6" color="white">
                        {item.value}
                      </VuiTypography>
                    </VuiBox>
                  </CardContent>
                </Card>
              ))}
            </Grid>

            {/* Right Section: Filter Word Frequency */}
            <Grid item xs={12} sm={6} lg={6}>
              <VuiTypography variant="h6" color="white" fontWeight="bold">
                Filter Word Frequency
              </VuiTypography>
              {/* Individual Interaction Cards */}
              {[
                { title: "Project Updates", value: "Repeated 45 times" },
                { title: "Deadline Review", value: "Repeated 15 times" },
                { title: "Team Performance", value: "Repeated 25 times" },
                { title: "Strategy Planning", value: "Repeated 35 times" },
              ].map((item, index) => (
                <Card key={index} sx={{ backgroundColor: "#1e1e1e", color: "white", marginTop: 1 }}>
                  <CardContent>
                    <VuiTypography variant="h6" color="white">
                      {item.title}
                    </VuiTypography>
                    <VuiBox flexWrap="noWrap" justifyContent="space-around" display="flex">
                      <VuiTypography variant="h6" color="white">
                        {item.value}
                      </VuiTypography>
                    </VuiBox>
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </Grid>
        </VuiBox>
      </Grid>
    </Grid>
  );
}

export default FilterCard;
