import React from "react";
import VuiInput from "components/VuiInput";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Person, Save, Cancel, Edit } from "@mui/icons-material";
import { IconButton, Tooltip, Avatar, Grid } from "@mui/material";

const PersonalInfo = ({ editedData, editMode, handleChange, onSave, onCancel, onEdit }) => (
  <VuiBox>
    <VuiTypography variant="h5" color="dark" mb={2} display="flex" alignItems="center">
      <Person sx={{ mr: 1 }} />
      Personal Information
    </VuiTypography>
    <Grid container spacing={3} alignItems="center">
      {/* Avatar Section */}
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <Avatar
          alt={editedData.name || "Profile Picture"}
          src={editedData.picture || "/placeholder-avatar.png"}
          sx={{ width: 120, height: 120, border: "4px solid rgba(0, 0, 0, 0.1)" }}
        />
      </Grid>

      {/* Information Section */}
      <Grid item xs={12} sm={8} md={9} lg={10}>
        {editMode ? (
          <>
            <VuiInput
              fullWidth
              label="Name"
              value={editedData.name}
              onChange={handleChange("name")}
              sx={{ mb: 2 }}
              placeholder="Enter your name"
            />
            <VuiInput
              fullWidth
              label="Email"
              value={editedData.email}
              onChange={handleChange("email")}
              sx={{ mb: 2 }}
              placeholder="Enter your email"
            />
            {/* Uncomment if time zone editing is needed */}
            {/* <VuiInput
              fullWidth
              label="Time Zone"
              value={editedData.timezone}
              onChange={handleChange("timezone")}
              placeholder="Select your time zone"
            /> */}
          </>
        ) : (
          <>
            <VuiTypography variant="body1" color="dark" mb={1}>
              <strong>Name:</strong> {editedData.name || "Not specified"}
            </VuiTypography>
            <VuiTypography variant="body1" color="dark" mb={1}>
              <strong>Email:</strong> {editedData.email || "Not specified"}
            </VuiTypography>
            <VuiTypography variant="body1" color="dark">
              <strong>Time Zone:</strong> {editedData.timeZone || "Not specified"}
            </VuiTypography>
          </>
        )}

        {/* Action Buttons */}
        {/* <VuiBox mt={2}>
          {editMode ? (
            <>
              <Tooltip title="Save changes">
                <IconButton color="primary" onClick={onSave}>
                  <Save />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel changes">
                <IconButton color="secondary" onClick={onCancel}>
                  <Cancel />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Edit profile">
              <IconButton color="primary" onClick={() => onEdit(true)}>
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </VuiBox> */}
      </Grid>
    </Grid>
  </VuiBox>
);

export default PersonalInfo;
