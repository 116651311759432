import React from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "examples/Tables/Table"; // Ensure the correct path to your Table component
import VuiButton from "components/VuiButton";

const MeetingTable = () => {
  // Define your columns
  const columns = [
    { name: "Meeting Title", align: "center" },
    { name: "Date", align: "center" },
    { name: "Time", align: "center" },
    { name: "Sentiment Report", align: "center" },
  ];

  // Define your rows with example data
  const rows = [
    {
      "Meeting Title": "Project Kickoff",
      Date: "2024-09-20",
      Time: "10:00 AM",
      "Sentiment Report": (
        <VuiButton variant="contained" color="primary" aria-label="View Project Kickoff Report">
          View Report
        </VuiButton>
      ),
    },
    {
      "Meeting Title": "Weekly Sync",
      Date: "2024-09-21",
      Time: "02:00 PM",
      "Sentiment Report": (
        <VuiButton variant="contained" color="primary" aria-label="View Weekly Sync Report">
          View Report
        </VuiButton>
      ),
    },
  ];

  return (
    <VuiBox sx={{ backgroundColor: "#0f1635", padding: "20px", borderRadius: "1rem" }}>
      <VuiTypography variant="h6" color="white" mb={2}>
        Upcoming Meetings
      </VuiTypography>
      <Table columns={columns} rows={rows} />
      <VuiBox mt={5}>
        <VuiButton variant="outlined" color="info" aria-label="View Full Sentiment Reports">
          Full Sentiment Reports by Meeting
        </VuiButton>
      </VuiBox>
    </VuiBox>
  );
};

export default MeetingTable;
