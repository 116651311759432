import React, { useRef, useEffect, useMemo } from "react";
import { IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import useApi from "shared/hooks/UseApi";
import { query } from "shared/services/ApiService/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "shared/Slices/chatHistorySlice";
import FileDropdown from "./Component/fileDropdown";

function ChatBox() {
  const chatHistoryRaw = useSelector((state) => state.chatHistory);
  const chatHistory = useMemo(
    () => (Array.isArray(chatHistoryRaw) ? chatHistoryRaw : []),
    [chatHistoryRaw]
  );
  const [message, setMessage] = React.useState("");
  const { callApi: callQuearyApi } = useApi(query);
  const chatEndRef = useRef(null);
  const dispatch = useDispatch();

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const handleSendMessage = async () => {
    if (message.trim()) {
      dispatch(addMessage({ user: "You", text: message }));
      setMessage("");
      const response = await callQuearyApi(message);
      if (response.status === 200) {
        setTimeout(() => {
          dispatch(
            addMessage({ user: "Querent", text: response.data.response || response.data.error })
          );
        }, 1000);
      }
    }
  };

  const handleInputChange = (e) => setMessage(e.target.value);
  const handleKeyPress = (e) => e.key === "Enter" && handleSendMessage();

  return (
    <VuiBox display="flex" flexDirection="column" height="100%">
      {/* Header with FileDropdown */}
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <VuiTypography variant="h6" color="white">
          Chat
        </VuiTypography>
        <FileDropdown />
      </VuiBox>

      {/* Chat History Area */}
      <VuiBox
        flexGrow={1}
        p={2}
        sx={{
          backgroundColor: "#f8f9fa",
          borderRadius: "10px",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
          overflowY: "auto",
          minHeight: "10rem",
          maxHeight: "20rem",
        }}
      >
        {chatHistory.map((chat, index) => (
          <VuiBox
            key={index}
            mb={2}
            display="flex"
            justifyContent={chat.user === "You" ? "flex-end" : "flex-start"}
          >
            <VuiBox
              sx={{
                padding: "10px",
                backgroundColor: chat.user === "You" ? "#4CAF50" : "#F5F5F5",
                color: chat.user === "You" ? "white" : "#0f1635",
                borderRadius: "8px",
                maxWidth: "70%",
              }}
            >
              <VuiTypography variant="body2">
                <strong>{chat.user}:</strong> {chat.text}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        ))}
        <div ref={chatEndRef}></div>
      </VuiBox>

      {/* Message Input and Send Button */}
      <VuiBox display="flex" mt={2}>
        <VuiInput
          type="text"
          variant="outlined"
          value={message}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Ask to Querent..."
          fullWidth
          sx={{ marginRight: "10px", backgroundColor: "#fff", borderRadius: "8px" }}
        />
        <IconButton
          color="primary"
          onClick={handleSendMessage}
          sx={{
            backgroundColor: "#0f1635",
            color: "white",
            borderRadius: "50%",
            padding: "12px",
          }}
        >
          <SendIcon />
        </IconButton>
      </VuiBox>
    </VuiBox>
  );
}

export default ChatBox;
