import React, { useEffect, useMemo } from "react";
import VuiBox from "components/VuiBox"; // Ensure this path is correct
import PieChart from "examples/Charts/PieCharts/Piechart";
import PropTypes from "prop-types";
import VuiButton from "components/VuiButton"; // Ensure this path is correct
import useChartError from "shared/hooks/UseCharError";

function Pie({ title = "Default Title", data = [30, 20, 40] }) {
  // Define chart options using useMemo
  const chartOptions = useMemo(
    () => ({
      title: {
        text: title,
        align: "left",
        style: {
          color: "white",
          fontSize: "18px",
          fontWeight: "bold",
        },
      },
      labels: ["Positive", "Neutral", "Negative"],
      legend: {
        position: "bottom",
        labels: {
          colors: "white",
        },
      },
    }),
    [title]
  ); // Recalculate when the title changes

  // Use the error handling hook to validate chart options and series data
  const { error, resetError, updateChart } = useChartError(chartOptions, data, "pie");

  // Update chart data when props change
  useEffect(() => {
    updateChart(chartOptions, data, "pie");
  }, [chartOptions, data, updateChart]);

  // If there's an error, display the error message
  if (error) {
    return (
      <VuiBox sx={{ height: "15rem", color: "white" }} p={2}>
        <h3>Chart Error</h3>
        <p aria-live="assertive">{error.message}</p>
        <VuiButton
          onClick={resetError}
          variant="contained"
          color="info"
          sx={{
            mt: 5,
            color: "white",
            circular: true,
            cursor: "pointer",
          }}
        >
          Try Again
        </VuiButton>
      </VuiBox>
    );
  }

  // If no error, render the PieChart
  return (
    <VuiBox color="white" p={2} sx={{ height: "20rem" }}>
      <PieChart options={chartOptions} series={data} />
    </VuiBox>
  );
}

Pie.propTypes = {
  title: PropTypes.string, // Title of the pie chart
  data: PropTypes.arrayOf(PropTypes.number), // Data series for the pie chart
};

export default Pie;
