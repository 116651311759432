// Settings.js
import React, { useEffect, useState } from "react";
import { Grid, Tabs, useTheme, useMediaQuery, Fade } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileDetails from "./Components/ProfileDetails";
import AccountSettings from "./Components/AccountSettings";
import AdminSettings from "./Components/AdminSettings";
import UserSettings from "./Components/UserSettings";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Person, Security, AdminPanelSettings } from "@mui/icons-material";
import { StyledTab } from "./Components/Styles";
import LoadingOverlay from "components/LoadingOverlay";
import ErrorState from "components/ErrorState";

function TabPanel({ children, value, index, ...other }) {
  return (
    <Fade in={value === index}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`settings-tabpanel-${index}`}
        aria-labelledby={`settings-tab-${index}`}
        {...other}
      >
        {value === index && <VuiBox sx={{ py: 3 }}>{children}</VuiBox>}
      </div>
    </Fade>
  );
}

function Settings() {
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const userData =
    useSelector((state) => state.user.userData) || JSON.parse(Cookies.get("userData") || "{}");

  const isAdmin = userData.role !== "admin";

  useEffect(() => {
    const initializeUserData = async () => {
      try {
        setIsLoading(true);
        if (!userData || Object.keys(userData).length === 0) {
          const cookieData = Cookies.get("userData");
          if (cookieData) {
            const parsedData = JSON.parse(cookieData);
            dispatch({ type: "SET_USER_DATA", payload: parsedData });
          } else {
            throw new Error("No user data found");
          }
        }
      } catch (err) {
        setError("Unable to load user settings. Please try again later.");
        console.error("Error loading user data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    initializeUserData();
  }, [dispatch, userData]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <ErrorState error={error} onRetry={() => window.location.reload()} />
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && <LoadingOverlay />}
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <VuiTypography variant="h2" color="white" fontWeight="bold" gutterBottom>
                Settings
              </VuiTypography>
              <ProfileDetails userData={userData} />
            </Grid>
          </Grid>
        </VuiBox>

        <VuiBox sx={{ borderBottom: 1, borderColor: "rgba(255, 255, 255, 0.1)" }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            scrollButtons={isMobile ? "auto" : false}
            aria-label="settings tabs"
            // indicatorColor="primary"
            // textColor="primary"
            variant="fullWidth"
            sx={{ mb: 2 }}
          >
            <StyledTab
              icon={<Person />}
              iconPosition="start"
              label={isMobile ? "" : "Profile"}
              aria-label="profile settings"
            />
            <StyledTab
              icon={<Security />}
              iconPosition="start"
              label={isMobile ? "" : "Security"}
              aria-label="security settings"
            />
            {isAdmin && (
              <StyledTab
                icon={<AdminPanelSettings />}
                iconPosition="start"
                label={isMobile ? "" : "Admin"}
                aria-label="admin settings"
              />
            )}
          </Tabs>
        </VuiBox>

        <TabPanel value={activeTab} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserSettings
                userData={userData}
                onSave={async (data) => {
                  dispatch({ type: "UPDATE_USER_DATA", payload: data });
                  Cookies.set("userData", JSON.stringify(data));
                }}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AccountSettings
                onSave={async (data) => {
                  // Handle password update
                  // Add your API call here
                }}
              />
            </Grid>
          </Grid>
        </TabPanel>

        {isAdmin && (
          <TabPanel value={activeTab} index={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AdminSettings />
              </Grid>
            </Grid>
          </TabPanel>
        )}
      </VuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Settings;
