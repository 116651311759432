import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Paper, CircularProgress, TextField, Tooltip } from "@mui/material";
import {
  SaveRounded as SaveRoundedIcon,
  CancelRounded as CancelRoundedIcon,
  EditRounded as EditRoundedIcon,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import MADatePicker from "components/MADatePicker/MADatePicker";
import DownloadReport from "components/DownloadReport";
import { setAnalytics1Meeting } from "shared/Slices/MeetingSlice";
import { getAnalytics1, updateActionItem } from "shared/services/ApiService/ApiService";
import useApi from "shared/hooks/UseApi";
import DataGridComponent from "components/DataGridComponent";
import MADropdown from "components/MADropdown/MADropdown";
import VuiButton from "components/VuiButton";

const EditableCell = ({ row, field, isEditing, value, onChange }) => {
  // if (!isEditing) return row.isSubRow ? value || "" : value || "N/A";
  if (!isEditing) {
    return (
      <Tooltip title={value || (row.isSubRow ? "" : "N/A")} placement="left-start">
        <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "wrap" }}>
          {value || (row.isSubRow ? "" : "N/A")}
        </span>
      </Tooltip>
    );
  }

  const renderField = () => {
    switch (field) {
      case "due_date":
        return (
          <MADatePicker value={value} onChange={(newValue) => onChange(field, newValue || "")} />
        );
      case "status":
        return (
          <MADropdown
            statuses={["Not Started", "In Progress", "Completed", "Delayed"]}
            selectedStatus={value || "Not Started"}
            isEditable
            onStatusChange={(newStatus) => onChange(field, newStatus)}
          />
        );
      default:
        return (
          <TextField
            variant="outlined"
            defaultValue={value || ""}
            onBlur={(e) => onChange(field, e.target.value)}
            fullWidth
          />
        );
    }
  };

  return renderField();
};

function AnalyticsTable() {
  const dispatch = useDispatch();
  const analytics = useSelector((state) => state.meetings.Analytics1Meeting) || [];
  const { error, loading, callApi: fetchAnalytics } = useApi(getAnalytics1);
  const { loading: updating, callApi: updateItem } = useApi(updateActionItem);
  const [editingId, setEditingId] = useState(null);
  const [initialData, setInitialData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [filters, setFilters] = useState({});
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [refreshKey, setRefreshKey] = useState(0); // State to trigger refresh

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchAnalytics();
      const items = response?.data?.analytics1?.action_items || [];
      console.log(items);
      dispatch(setAnalytics1Meeting(items));
    } catch (error) {
      console.error("Error fetching analytics:", error);
      dispatch(setAnalytics1Meeting([]));
    }
  }, [fetchAnalytics, dispatch]);

  const handleEdit = useCallback(
    (id) => {
      setEditingId(id);
      const row = analytics.find((item) => item.id === id) || {};
      setInitialData(row);
      setEditedData(row);
    },
    [analytics]
  );

  const handleSave = useCallback(
    async (id) => {
      const changedData = Object.fromEntries(
        Object.entries(editedData).filter(([key, value]) => initialData[key] !== value)
      );

      if (Object.keys(changedData).length) {
        const response = await updateItem(id, changedData);
        if (response.status === 200) {
          await fetchData();
          setEditingId(null);
          setInitialData({});
          setEditedData({});
        }
      } else {
        setEditingId(null);
      }
    },
    [editedData, initialData, fetchData, updateItem]
  );

  const handleCancel = () => {
    setEditingId(null);
    setEditedData(initialData);
  };

  const toggleRowExpansion = useCallback((id) => {
    setExpandedRows((prev) => {
      const next = new Set(prev);
      next.has(id) ? next.delete(id) : next.add(id);
      return next;
    });
  }, []);

  const processRows = useCallback(
    (rows) => {
      return rows.flatMap((row) => {
        const actionItems = Array.isArray(row.action_item)
          ? row.action_item
          : (row.action_item || "").split("\n").filter(Boolean);

        const mainRow = { ...row, action_item: actionItems[0], hasSubRows: actionItems.length > 1 };
        const subRows = expandedRows.has(row.id)
          ? actionItems.slice(1).map((item, index) => ({
              // ...row,
              id: `${row.id}-${index + 1}`,
              action_item: item,
              isSubRow: true,
            }))
          : [];
        return [mainRow, ...subRows];
      });
    },
    [expandedRows]
  );

  const columns = useMemo(
    () => [
      {
        field: "expander",
        headerName: "",
        width: 50,
        sortable: false,
        renderCell: ({ row }) =>
          row.hasSubRows &&
          !row.isSubRow && (
            <IconButton size="small" onClick={() => toggleRowExpansion(row.id)}>
              {expandedRows.has(row.id) ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          ),
      },
      {
        field: "meeting_subject",
        headerName: "Meeting Subject",
        flex: 1,
        minWidth: 150,
        renderCell: (params) => (
          <EditableCell
            row={params.row}
            field="meeting_subject"
            isEditing={false}
            value={params.value}
          />
        ),
      },
      {
        field: "action_item",
        headerName: "Action Items",
        flex: 1,
        minWidth: 250,
        renderCell: (params) => (
          <EditableCell
            row={params.row}
            field="action_item"
            isEditing={false}
            value={params.value}
          />
        ),
      },
      {
        field: "date_opened",
        headerName: "Date Opened",
        minWidth: 130,
        renderCell: (params) => (
          <EditableCell
            row={params.row}
            field="date_opened"
            isEditing={false}
            value={params.value}
          />
        ),
      },
      {
        field: "due_date",
        headerName: "Due Date",
        minWidth: 170,
        renderCell: (params) => (
          <EditableCell
            row={params.row}
            field="due_date"
            isEditing={editingId === params.row.id}
            value={editingId === params.row.id ? editedData.due_date : params.value}
            onChange={(field, value) => setEditedData((prev) => ({ ...prev, [field]: value }))}
          />
        ),
      },
      {
        field: "responsible",
        headerName: "Responsible",
        flex: 1,
        minWidth: 140,
        renderCell: (params) => (
          <EditableCell
            row={params.row}
            field="responsible"
            isEditing={editingId === params.row.id}
            value={params.value}
            onChange={(field, value) => setEditedData((prev) => ({ ...prev, [field]: value }))}
          />
        ),
      },
      {
        field: "priority",
        headerName: "Priority",
        minWidth: 100,
        renderCell: (params) => (
          <EditableCell
            row={params.row}
            field="priority"
            isEditing={editingId === params.row.id}
            value={params.value}
            onChange={(field, value) => setEditedData((prev) => ({ ...prev, [field]: value }))}
          />
        ),
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 150,
        renderCell: (params) => (
          <EditableCell
            row={params.row}
            field="status"
            isEditing={editingId === params.row.id}
            value={editingId === params.row.id ? editedData.status : params.value}
            onChange={(field, value) => setEditedData((prev) => ({ ...prev, [field]: value }))}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 100,
        renderCell: ({ row }) => {
          if (row.isSubRow) return null;
          return editingId === row.id ? (
            <>
              <IconButton color="primary" onClick={() => handleSave(row.id)}>
                <SaveRoundedIcon />
              </IconButton>
              <IconButton color="error" onClick={handleCancel}>
                <CancelRoundedIcon />
              </IconButton>
            </>
          ) : (
            <IconButton color="primary" onClick={() => handleEdit(row.id)}>
              <EditRoundedIcon />
            </IconButton>
          );
        },
      },
    ],
    [editingId, expandedRows, handleEdit, handleSave, handleCancel]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, refreshKey]);

  const filteredRows = useMemo(
    () =>
      analytics.filter((row) =>
        Object.entries(filters).every(([field, value]) =>
          row[field]?.toString().toLowerCase().includes(value.toLowerCase())
        )
      ),
    [analytics, filters]
  );

  const rows = processRows(filteredRows);

  return (
    <VuiBox px={3} pt={3} pb={2}>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center">
        <VuiTypography variant="h5" color="light">
          Analytics1 Meeting Table
        </VuiTypography>
        <VuiBox display="flex" justifyContent="space-evenly" flexDirection="row">
          <DownloadReport data={analytics} />
          <VuiButton
            variant="outlined"
            color="success"
            disabled={loading}
            onClick={() => setRefreshKey((prev) => prev + 1)} // Increment refreshKey to trigger update
            sx={{
              m: 2,
              circular: true,
              color: "white",
              width: "10rem",
              height: "3rem",
              textAlign: "center",
              boxShadow: 2,
              "&:hover": {
                boxShadow: 4,
              },
            }}
          >
            Refresh
          </VuiButton>
        </VuiBox>
      </VuiBox>
      {error ? (
        <VuiTypography color="error">Error: {error.message}</VuiTypography>
      ) : loading ? (
        <VuiBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minHeight="20rem"
        >
          <CircularProgress color="primary" size={110} />
          <VuiTypography variant="body1" color="white" mt={5}>
            Loading...
          </VuiTypography>
        </VuiBox>
      ) : (
        <Paper>
          <DataGridComponent rows={rows} columns={columns} />
        </Paper>
      )}
    </VuiBox>
  );
}

export default AnalyticsTable;
