import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { StyledCard, StyledTab } from "../Styles";
import PersonalInfo from "./Components/PersonalInfo";
import ProfessionalInfo from "./Components/ProfessionalInfo";
import SkillsInfo from "./Components/SkillsInfo";

const ProfileDetails = ({ userData, onUpdateProfile }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [timezone, setTimezone] = useState("");
  const [editedData, setEditedData] = useState({ ...userData, timeZone: "" });

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(userTimezone);
  }, []);

  useEffect(() => {
    setEditedData({ ...userData, timeZone: timezone });
  }, [userData, timezone]);

  const handleChange = (field) => (event) => {
    setEditedData({ ...editedData, [field]: event.target.value });
  };

  const handleSave = () => {
    onUpdateProfile(editedData);
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditedData({ ...userData });
    setEditMode(false);
  };

  return (
    <StyledCard>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        sx={{ mb: 2 }}
      >
        <StyledTab label="Basic Info" />
        {/* <StyledTab label="Professional" />
        <StyledTab label="Skills" /> */}
      </Tabs>

      {activeTab === 0 && (
        <PersonalInfo
          editedData={editedData}
          editMode={editMode}
          handleChange={handleChange}
          onSave={handleSave}
          onCancel={handleCancel}
          onEdit={setEditMode}
        />
      )}
      {activeTab === 1 && (
        <ProfessionalInfo editedData={editedData} editMode={editMode} handleChange={handleChange} />
      )}
      {activeTab === 2 && (
        <SkillsInfo editedData={editedData} editMode={editMode} handleChange={handleChange} />
      )}
    </StyledCard>
  );
};

export default ProfileDetails;
