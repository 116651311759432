export const buttonStyles = {
  variant: "contained",
  color: "primary",
  sx: {
    m: 2,
    circular: true,
    color: "white",
    width: "10rem",
    height: "3rem",
    textAlign: "center",
    boxShadow: 2,
    "&:hover": {
      boxShadow: 4,
    },
  },
};
