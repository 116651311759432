import { PublicClientApplication } from "@azure/msal-browser";
import config from "./config";
export const msalConfig = {
  auth: {
    clientId: config.MICROSOFT_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${config.MICROSOFT_TENANT_ID}`,
    redirectUri: config.MICROSOFT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);
