import { Padding } from "@mui/icons-material";
import { Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton"; // Assuming you have a custom button component
import React from "react";

const buttonConfig = [
  { label: "Objections" },
  { label: "Team Morale" },
  { label: "Engagement Monitor" },
  { label: "Training Needs" },
  { label: "Time Management" },
  { label: "Effectiveness Score" },
];

function ActionButtons() {
  // Base style for circular buttons
  const buttonStyle = {
    borderRadius: "50%",
    height: "6rem",
    width: "6rem",
    Padding: "10px",
    margin: 1, // Optional: Add margin for spacing between buttons
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <VuiBox sx={{ display: "flex", flexDirection: "column", gap: 2, flexWrap: "wrap" }}>
      {/* Row: All buttons in a single row */}
      <Grid container spacing={2} justifyContent="center">
        {buttonConfig.map((button, index) => (
          <Grid item key={index}>
            <VuiButton
              variant="contained"
              color="info"
              sx={buttonStyle}
              aria-label={button.label} // Accessibility improvement
            >
              {button.label}
            </VuiButton>
          </Grid>
        ))}
      </Grid>

      {/* Row: Download Report Button */}
      <VuiBox sx={{ display: "flex", justifyContent: "center" }}>
        <VuiButton
          variant="contained"
          color="primary"
          sx={{ height: "60px", width: "100%" }} // Full width for the download button
          aria-label="Download Report" // Accessibility improvement
        >
          Download Report
        </VuiButton>
      </VuiBox>
    </VuiBox>
  );
}

export default ActionButtons;
