import React, { useState, useCallback } from "react";
import { CardContent, Grid, Snackbar, Alert } from "@mui/material";
import { StyledCard } from "../Styles";
import ProfilePicture from "./Components/ProfilePicture";
import UserForm from "./Components/UserForm";

const UserSettings = ({ userData, onSave }) => {
  // State management
  const [formData, setFormData] = useState({
    name: userData?.name || "",
    email: userData?.email || "",
    phone: userData?.phone || "",
    profilePic: userData?.picture || "",
    bio: userData?.bio || "",
  });

  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [errors, setErrors] = useState({});

  // Form validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (formData.phone && !/^\+?[\d\s-]{10,}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // Event handlers
  const handleInputChange = useCallback(
    (name, value) => {
      setFormData((prev) => ({ ...prev, [name]: value }));
      if (errors[name]) {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    },
    [errors]
  );

  const handleProfilePicChange = useCallback((profilePic) => {
    setFormData((prev) => ({ ...prev, profilePic }));
  }, []);

  const handleSave = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate API call
      if (onSave) {
        await onSave(formData);
      }
      setNotification({
        open: true,
        message: "Profile updated successfully",
        severity: "success",
      });
    } catch (error) {
      setNotification({
        open: true,
        message: error.message || "Error updating profile",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledCard>
      <CardContent>
        <h4>Profile Settings</h4>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <ProfilePicture
              profilePic={formData.profilePic}
              onProfilePicChange={handleProfilePicChange}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <UserForm
              formData={formData}
              onInputChange={handleInputChange}
              loading={loading}
              onSave={handleSave}
              errors={errors}
            />
          </Grid>
        </Grid>
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={notification.severity}
            onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </CardContent>
    </StyledCard>
  );
};

export default UserSettings;
