import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  user: JSON.parse(Cookies.get("userData") || "{}"), // Initialize user from cookies or an empty object
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      // Set user data from payload
      state.user = action.payload || {};
      // Store user data in cookies
      Cookies.set("userData", JSON.stringify(state.user), {
        expires: 7, // Cookie expires in 7 days
        secure: true, // Secure flag for HTTPS
        sameSite: "Strict", // SameSite policy for cookies
      });
    },
    removeUser: (state) => {
      // Clear user data from state
      state.user = {};
      // Remove user data from cookies
      Cookies.remove("userData");
    },
  },
});

// Export actions and reducer
export const { setUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
