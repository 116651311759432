import React, { useState } from "react";
import { Modal, Snackbar } from "@mui/material";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import useApi from "shared/hooks/UseApi";
import { upload } from "shared/services/ApiService/ApiService";

export const FolderUpload = () => {
  const [open, setOpen] = useState(false);
  const [folder, setFolder] = useState(null);
  const [uploadResults, setUploadResults] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { loading, callApi } = useApi(upload);

  // Create a reference for the VuiInput file input

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFolder(null);
    setUploadResults([]); // Reset results when closing
  };

  const handleFolderChange = (event) => {
    const selectedFiles = Array.from(event.target.files).filter(
      (file) => file.type === "application/pdf"
    );

    if (selectedFiles.length === 0) {
      setSnackbarMessage("Only PDF files are allowed.");
      setSnackbarOpen(true);
    } else {
      setFolder(selectedFiles);
    }
  };

  const handleFolderUpload = async () => {
    if (!folder || folder.length === 0) {
      setSnackbarMessage("No PDF files selected.");
      setSnackbarOpen(true);
      return;
    }

    const results = [];

    for (const file of folder) {
      const formData = new FormData();
      formData.append("file", file); // Assuming your backend expects a single file

      try {
        const response = await callApi(formData);
        if (response.status == 200) {
          if (response?.data?.message) {
            results.push({
              fileName: file.name,
              status: "success",
              message: "Uploaded!",
            });
          } else if (response?.data.error) {
            results.push({
              fileName: file.name,
              status: "error",
              message: "Failed!.",
            });
          }
        } else {
          results.push({
            fileName: file.name,
            status: "error",
            message: "Failed!.",
          });
        }
      } catch (err) {
        results.push({ fileName: file.name, status: "error", message: err.message });
      }
    }

    setUploadResults(results);
    setSnackbarMessage("Upload complete!");
    setSnackbarOpen(true);

    // Clear the file input after uploading
    setFolder(null);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <VuiBox>
      <VuiButton
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{
          color: "white",
          height: "3rem",
          width: "10rem",
          textAlign: "center",
          circular: true,
          boxShadow: 2,
          "&:hover": { boxShadow: 4 },
        }}
      >
        Upload Folder
      </VuiButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="folder-upload-modal"
        aria-describedby="modal-for-folder-upload"
      >
        <VuiBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "#2D3250",
            borderRadius: "10px",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            p: 4,
            color: "white",
          }}
        >
          <VuiTypography
            id="folder-upload-modal"
            variant="h6"
            textAlign="center"
            mb={2}
            color="white"
          >
            Upload Folder
          </VuiTypography>

          <VuiInput
            type="file"
            accept="application/pdf" // Restrict to PDF files
            inputProps={{ webkitdirectory: "true", directory: "true", multiple: true }}
            onChange={handleFolderChange}
            sx={{ display: "block", mb: 2 }}
            disabled={loading}
          />

          <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <VuiButton
              variant="contained"
              color="success"
              onClick={handleFolderUpload}
              disabled={!folder || loading}
            >
              Upload
            </VuiButton>

            <VuiButton variant="contained" color="error" onClick={handleClose} sx={{ ml: 2 }}>
              Close
            </VuiButton>
          </VuiBox>

          {/* Display upload results */}
          <VuiBox mt={2} sx={{ maxHeight: "150px", overflow: "auto" }}>
            {uploadResults.length > 0 && (
              <VuiTypography variant="body2" color="white" textAlign="center" mb={2}>
                Upload Results:
              </VuiTypography>
            )}
            {uploadResults.map((result, index) => (
              <VuiTypography
                key={index}
                variant="body2"
                color={result.status === "success" ? "success" : "error"}
                textAlign="center"
              >
                {result.fileName} {result.message}
              </VuiTypography>
            ))}
          </VuiBox>

          {/* Snackbar for general notifications at the top */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          />
        </VuiBox>
      </Modal>
    </VuiBox>
  );
};
