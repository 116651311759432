import React from "react";
import { Grid } from "@mui/material"; // Assuming you still use Material-UI Grid
import { InfoCard } from "./Components";

function MeetingCard({ data }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        {data.totalStorage?.total}
        <InfoCard
          title="Total Storage Used"
          value={`Used:  ${data.used.toFixed(2)} GB of ${data.total.toFixed(2)} GB`}
          percentage={
            data.total > 0 ? `+${100 - ((data.used / data.total) * 100).toFixed(2)} % ` : "No data"
          }
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <InfoCard title="Total Meeting Numbers" value="70" percentage="+56%" />
      </Grid>
      <Grid item xs={12} sm={12}>
        <InfoCard title="Monthly Renewal" value="$100" percentage="+56%" />
      </Grid>
      {/* <Grid item xs={12} sm={12}>
        <VuiButton
          variant="contained"
          color="primary"
          sx={{
            m: 2,
            circular: true,
            color: "white",
            width: "18rem",
            textAlign: "center",
            boxShadow: 2,
            "&:hover": {
              boxShadow: 4,
            },
          }}
        >
          User/Admin
        </VuiButton>
      </Grid> */}
    </Grid>
  );
}

export default MeetingCard;
