import { createSlice } from "@reduxjs/toolkit";

const chatHistorySlice = createSlice({
  name: "chatHistory",
  initialState: [{ user: "Querent", text: "Welcome to Querent!" }], // Initial welcome message
  reducers: {
    addMessage: (state, action) => {
      state.push(action.payload); // Add new message to the chat history
    },
    clearChat: (state) => {
      state = []; // Clear chat history
    },
  },
});

export const { addMessage, clearChat } = chatHistorySlice.actions;
export default chatHistorySlice.reducer;
