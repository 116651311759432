import React from "react";
import { Grid, Card, CardContent } from "@mui/material"; // Assuming you still use Material-UI Grid
import VuiTypography from "components/VuiTypography"; // Ensure this path is correct
import VuiBox from "components/VuiBox"; // Ensure this path is correct

// Reusable PriorityCard Component
const PriorityCard = ({ title, amount, percentage, color }) => (
  <Card sx={{ backgroundColor: color }}>
    <CardContent>
      <VuiTypography variant="h6" color="white">
        {title}
      </VuiTypography>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <VuiTypography variant="h5" color="white">
            {amount}
          </VuiTypography>
        </Grid>
        <Grid item>
          <VuiTypography variant="body2" color="success">
            {percentage}
          </VuiTypography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

function PriorityCards() {
  // Data for the cards
  const cardsData = [
    {
      title: "High Priority",
      amount: "53",
      percentage: "+56.34%",
      color: "rgba(255,0,0,0.1)",
    },
    { title: "Low Priority", amount: "26", percentage: "+3%", color: "rgba(0,255,0,0.1)" },
  ];

  return (
    <Grid container spacing={2}>
      {cardsData.map((card, index) => (
        <Grid item xs={12} sm={12} key={index}>
          {" "}
          {/* Adjusting for 2 cards per row on larger screens */}
          <PriorityCard
            title={card.title}
            amount={card.amount}
            percentage={card.percentage}
            color={card.color}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default PriorityCards;
