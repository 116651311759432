import React from "react";
import { Grid, Card, CardContent } from "@mui/material";
import VuiTypography from "components/VuiTypography"; // Ensure this path is correct

function TeamCard() {
  // Data for the team members
  const teamMembers = [
    { name: "Devanjan", role: "Developer" },
    { name: "Akash", role: "Designer" },
    { name: "Prasad", role: "Project Manager" },
    { name: "Ashutosh", role: "Tester" },
  ];

  return (
    <Grid container spacing={2}>
      {/* Team Title Card */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <VuiTypography variant="h5" color="white">
                  Team
                </VuiTypography>
              </Grid>
              <Grid item>
                <VuiTypography variant="body2" color="white">
                  Projects
                </VuiTypography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* Team Members List Card */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {teamMembers.map((member, index) => (
                <Grid
                  item
                  xs={12}
                  key={index} // Ideally, use a unique id if available
                >
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <VuiTypography variant="h6" color="white">
                        {member.name}
                      </VuiTypography>
                    </Grid>
                    <Grid item>
                      <VuiTypography variant="body2" color="white">
                        {member.role}
                      </VuiTypography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default TeamCard;
