import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import BarChart from "examples/Charts/BarCharts/BarChart";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import useChartError from "shared/hooks/UseCharError";

const EngagementBarChart = ({ title = "Engagement Bar Chart" }) => {
  const chartData = useMemo(
    () => ({
      series: [
        {
          name: "Engagement",
          data: [80, 70, 90],
        },
        {
          name: "Pause",
          data: [20, 30, 10],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          foreColor: "white",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: "50%",
          },
        },
        xaxis: {
          categories: ["Person 1", "Person 2", "Person 3"],
          labels: {
            style: {
              colors: "white",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "white",
            },
          },
        },
        title: {
          text: title,
          align: "center",
          style: {
            color: "white",
            fontSize: "18px",
            fontWeight: "bold",
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          labels: {
            colors: "white",
          },
        },
        dataLabels: {
          style: {
            colors: ["white"],
          },
        },
        grid: {
          borderColor: "rgba(255, 255, 255, 0.1)",
        },
      },
    }),
    [title]
  );

  const { error, resetError, updateChart } = useChartError(chartData.options, chartData.series);

  useEffect(() => {
    updateChart(chartData.options, chartData.series, "bar");
  }, [chartData, updateChart]);

  if (error) {
    return (
      <VuiBox
        sx={{
          backgroundColor: "#0f1635",
          height: "20rem",
          padding: "20px",
          borderRadius: "1rem",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3>Chart Error</h3>
        <p>{error.message}</p>
        <VuiButton
          onClick={resetError}
          variant="contained"
          color="info"
          sx={{
            mt: 3,
            color: "white",
            circular: true,
            cursor: "pointer",
          }}
        >
          Try Again
        </VuiButton>
      </VuiBox>
    );
  }

  return (
    <VuiBox
      sx={{ backgroundColor: "#0f1635", height: "20rem", padding: "20px", borderRadius: "10px" }}
    >
      <BarChart options={chartData.options} series={chartData.series} />
    </VuiBox>
  );
};

EngagementBarChart.propTypes = {
  title: PropTypes.string,
};

export default EngagementBarChart;
