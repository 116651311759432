import React, { useState } from "react";
import { Modal } from "@mui/material";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";

export const AudioVideoUpload = () => {
  const [open, setOpen] = useState(false);
  const [media, setMedia] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setMedia(null); // Clear media file after closing modal
    setUploadProgress(0); // Reset upload progress
    setOpen(false);
  };

  const handleMediaChange = (event) => {
    setMedia(event.target.files[0]);
  };

  const handleMediaUpload = async () => {
    if (!media) return;

    const formData = new FormData();
    formData.append("media", media); // Add selected media file

    try {
      // API call to upload the media file
      // const response = await axios.post("/api/upload-media", formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      //   onUploadProgress: (progressEvent) => {
      //     const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //     setUploadProgress(progress);
      //   },
      // });

      // console.log("Media upload successful:", response.data);
      handleClose();
    } catch (error) {
      console.error("Error uploading media:", error);
    }
  };

  return (
    <VuiBox>
      <VuiButton
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{
          color: "white",
          height: "3rem",
          width: "10rem",
          textAlign: "center",
          circular: true,
          boxShadow: 2,
          "&:hover": { boxShadow: 4 },
        }}
      >
        Audio & Video
      </VuiButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="audio-video-upload-modal"
        aria-describedby="modal-for-audio-video-upload"
      >
        <VuiBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "#2D3250", // Dark background for the modal
            borderRadius: "10px",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            p: 4,
          }}
        >
          <VuiTypography
            id="audio-video-upload-modal"
            variant="h6"
            textAlign="center"
            mb={2}
            color="white" // White text to match the dark background
          >
            Upload Audio or Video
          </VuiTypography>

          <VuiInput
            type="file"
            onChange={handleMediaChange}
            accept="audio/*,video/*"
            sx={{ my: "2rem", display: "block" }}
          />

          {/* Show upload progress */}
          {uploadProgress > 0 && (
            <VuiTypography textAlign="center" color="primary">
              Upload Progress: {uploadProgress}%
            </VuiTypography>
          )}

          <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <VuiButton
              variant="contained"
              color="success"
              onClick={handleMediaUpload}
              disabled={!media}
            >
              Upload
            </VuiButton>

            <VuiButton variant="contained" color="error" onClick={handleClose} sx={{ ml: 2 }}>
              Cancel
            </VuiButton>
          </VuiBox>
        </VuiBox>
      </Modal>
    </VuiBox>
  );
};
