import { useState, useEffect, useRef, useCallback, useMemo } from "react";

const useChartError = (initialOptions, initialSeries, chartType) => {
  const [error, setError] = useState(null);
  const optionsRef = useRef(initialOptions);
  const seriesRef = useRef(initialSeries);
  const chartTypeRef = useRef(chartType);

  const validateChart = useCallback(() => {
    if (!optionsRef.current || typeof optionsRef.current !== "object") {
      setError(new Error("Invalid chart options: must be an object."));
      return;
    }

    switch (chartTypeRef.current) {
      case "pie":
      case "donut":
        if (
          !Array.isArray(seriesRef.current) ||
          seriesRef.current.some((value) => typeof value !== "number")
        ) {
          setError(
            new Error("Invalid series data for pie/donut chart: must be an array of numbers.")
          );
          return;
        }
        break;

      case "line":
      case "bar":
        if (!Array.isArray(seriesRef.current) || seriesRef.current.length === 0) {
          setError(new Error("Invalid series data: must be a non-empty array."));
          return;
        }
        if (
          seriesRef.current.some(
            (item) =>
              !Array.isArray(item.data) || item.data.some((value) => typeof value !== "number")
          )
        ) {
          setError(new Error("Series data must contain only numbers."));
          return;
        }
        break;

      default:
        setError(new Error(`Unsupported chart type: ${chartTypeRef.current}.`));
        return;
    }

    setError(null); // Clear error if everything is valid
  }, []);

  useEffect(() => {
    validateChart();
  }, [validateChart]);

  const updateChart = useCallback(
    (newOptions, newSeries, newChartType) => {
      optionsRef.current = newOptions;
      seriesRef.current = newSeries;
      if (newChartType) chartTypeRef.current = newChartType;
      validateChart();
    },
    [validateChart]
  );

  const resetError = useCallback(() => setError(null), []);

  // Memoized values for better performance
  const memoizedOptions = useMemo(() => optionsRef.current, [optionsRef.current]);
  const memoizedSeries = useMemo(() => seriesRef.current, [seriesRef.current]);

  return { error, resetError, updateChart, memoizedOptions, memoizedSeries };
};

export default useChartError;
