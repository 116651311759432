import React from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Card, CardContent, CircularProgress, Grid } from "@mui/material";

function Actions({ actionsData, loading, error }) {
  const backgroundColors = [
    "#1b5e20", // Dark Green
    "#f57f17", // Dark Orange
    "#b71c1c", // Dark Red
    "#0d47a1", // Dark Blue
  ];
  return (
    <VuiBox p={3}>
      {loading ? (
        <VuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="20rem"
        >
          <CircularProgress color="primary" size={110} />
          <VuiTypography variant="body1" color="white" mt={5}>
            Loading...
          </VuiTypography>
        </VuiBox>
      ) : error ? (
        <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <VuiTypography color="error" variant="h6" textAlign="center">
            Failed to load data. Please try again.
          </VuiTypography>
        </VuiBox>
      ) : (
        <Grid container spacing={2}>
          {actionsData.map(({ title, value }, index) => (
            <Grid item xs={3} sm={2} lg={3} key={index}>
              <Card
                sx={{
                  backgroundColor: backgroundColors[index % backgroundColors.length],
                  borderRadius: "8px", // Ensure rounded corners
                  boxShadow: "none", // Disable shadow
                  color: "white", // Set text color for consistency
                  position: "relative", // Ensure card uses full container
                  overflow: "hidden", // Avoid content overflow
                  minHeight: "15rem", // Allow flexibility in height
                }}
                aria-label={title} // Accessibility improvement
              >
                <CardContent
                  sx={{
                    padding: "1.5rem",
                  }}
                >
                  <VuiTypography color="white" variant="h6" fontWeight="bold">
                    {title}
                  </VuiTypography>
                  <VuiTypography color="white" variant="h4">
                    {value}
                  </VuiTypography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </VuiBox>
  );
}

export default Actions;
