import React from "react";
import { Card, Grid } from "@mui/material";
import Symantic from "./components/Symantic-analysis/Symantic";
import Individual from "./components/Individual-reports/Individual";
import ActionButtons from "./components/Buttons/Buttons";

function Analysis() {
  return (
    <Grid container spacing={5}>
      {/* Symantic Report */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
          <Symantic />
        </Card>
      </Grid>

      {/* Individual Report */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
          <Individual /> {/* First instance */}
        </Card>
      </Grid>

      {/* Action Buttons */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card>
          <ActionButtons />
          {/* Added onClick for functionality */}
        </Card>
      </Grid>
    </Grid>
  );
}

export default Analysis;
