import React, { useEffect, useMemo, useCallback } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "examples/Tables/Table";
import VuiButton from "components/VuiButton";
import useApi from "shared/hooks/UseApi";
import { pastMeeting } from "shared/services/ApiService/ApiService";
import Link from "@mui/material/Link";
import MAAttendeeDropdown from "components/MAAttendeeDropdown/MAAttendeeDropdown";
import { useDispatch, useSelector } from "react-redux";
import { setPastMeeting } from "shared/Slices/MeetingSlice";
import MATruncatedText from "components/MATrucatedText/MATruncatedText";
import CircularProgress from "@mui/material/CircularProgress"; // Importing CircularProgress for loading state
import MAScrollbar from "components/MAScrollbar/MAScrollbar";

function MeetingTable() {
  const dispatch = useDispatch();
  const meetings = useSelector((state) => state.meetings.pastMeeting);
  const { error, loading, callApi } = useApi(pastMeeting);

  const meetingColumns = useMemo(
    () => [
      { name: "Meeting Name", align: "center" },
      { name: "Date", align: "center" },
      { name: "Start", align: "center" },
      { name: "Duration", align: "center" },
      { name: "Attendees", align: "center" },
      { name: "View MOM", align: "center" },
      { name: "Download MOM", align: "center" },
      { name: "Push Action Item", align: "center" },
      { name: "Send MOM", align: "center" },
      { name: "View Recording", align: "center" },
      { name: "Status", align: "center" },
      { name: "Joining Mode", align: "center" },
    ],
    []
  );

  const formatMeetingData = useCallback((meetings) => {
    return meetings.map((meeting) => {
      const dateObj = new Date(meeting.start);

      // Format date as YY/MM/DD
      const formattedDate = dateObj.toLocaleDateString(undefined, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      // Format time as HH:MM
      const formattedStartTime = dateObj.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // 24-hour format
      });
      const startTime = new Date(meeting.start);
      const endTime = new Date(meeting.end);
      const duration = meeting.duration
        ? `${Math.round(meeting.duration / 60)} minutes`
        : `${(endTime - startTime) / 60000} minutes`;
      // const formattedStartTime = startTime.toLocaleTimeString([], {
      //   hour: "2-digit",
      //   minute: "2-digit",
      //   hour12: true,
      // });

      const disableButtons = !meeting.join_with_jotter;

      return {
        "Meeting Name": <MATruncatedText text={meeting.summary || "No title"} />,
        Date: formattedDate,
        Start: formattedStartTime,
        Duration: duration,
        Attendees:
          meeting.attendees && meeting.attendees.length > 0 ? (
            <MAAttendeeDropdown attendees={meeting.attendees} />
          ) : (
            "No attendees"
          ),
        "View MOM": (
          <VuiButton variant="contained" color="info" disabled={disableButtons}>
            <Link href={meeting.mom} color="inherit" target="_blank" rel="noopener noreferrer">
              View
            </Link>
          </VuiButton>
        ),
        "Download MOM": (
          <VuiButton
            variant="contained"
            color="warning"
            disabled={!meeting.mom_download_url || disableButtons}
            onClick={async () => {
              if (!meeting.mom_download_url) return;

              try {
                const response = await fetch(meeting.mom_download_url);
                const blob = await response.blob();

                const url = new URL(meeting.mom_download_url);
                const params = new URLSearchParams(url.search);
                const contentDisposition = params.get("response-content-disposition");
                let filename = "MOM.pdf";

                if (contentDisposition) {
                  const matches = contentDisposition.match(/filename\*?=(['"]?)(.+?)\1/);
                  if (matches && matches[2]) {
                    filename = decodeURIComponent(matches[2]);
                  }
                }

                const link = document.createElement("a");
                const blobUrl = window.URL.createObjectURL(blob);
                link.href = blobUrl;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
              } catch (error) {
                console.error("Error downloading file:", error);
              }
            }}
          >
            Download MOM
          </VuiButton>
        ),
        "Push Action Item": (
          <VuiButton variant="contained" color="success" disabled={disableButtons}>
            Push
          </VuiButton>
        ),
        "Send MOM": (
          <VuiButton variant="contained" color="primary" disabled={disableButtons}>
            Send
          </VuiButton>
        ),
        "View Recording": (
          <VuiButton variant="contained" color="info" disabled={disableButtons}>
            <Link
              href={meeting.raw_meeting_recording}
              color="inherit"
              target="_blank"
              rel="noopener noreferrer"
            >
              Recording
            </Link>
          </VuiButton>
        ),
        Status: meeting.join_with_jotter ? "Completed" : "In-progress",
        "Joining Mode": meeting.join_with_jotter ? "Jotter" : "In-Person",
      };
    });
  }, []);

  const handleUpdate = useCallback(async () => {
    const response = await callApi();
    if (response && response.data.past_meetings) {
      dispatch(setPastMeeting(response.data.past_meetings));
    }
  }, [callApi, dispatch]);

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate]);

  const formattedMeetings = useMemo(
    () => formatMeetingData(meetings),
    [meetings, formatMeetingData]
  );

  return (
    <VuiBox>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <VuiTypography variant="h5" color="light">
          Past Meetings Table
        </VuiTypography>
        <VuiButton variant="outlined" color="success" disabled={loading} onClick={handleUpdate}>
          Refresh
        </VuiButton>
      </VuiBox>

      {loading ? (
        <VuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="20rem"
        >
          <CircularProgress color="primary" size={110} />
          <VuiTypography variant="body1" color="white" mt={5}>
            Loading...
          </VuiTypography>
        </VuiBox>
      ) : error ? (
        <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <VuiTypography color="error" variant="h6">
            Failed to load data. Please try again.
          </VuiTypography>
        </VuiBox>
      ) : (
        <MAScrollbar>
          {meetings.length > 0 ? (
            <Table columns={meetingColumns} rows={formattedMeetings} />
          ) : (
            <VuiTypography variant="body1" color="error">
              No meetings found.
            </VuiTypography>
          )}
        </MAScrollbar>
      )}
    </VuiBox>
  );
}

export default MeetingTable;
