import React, { useCallback } from "react";
import { Avatar, Button, Typography, Box } from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";

const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"];
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const ProfilePicture = ({ profilePic, onProfilePicChange }) => {
  const handleProfilePicChange = useCallback(
    async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        alert("Please upload only JPG, PNG or GIF files");
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        alert("File size should not exceed 5MB");
        return;
      }

      const base64 = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });

      onProfilePicChange(base64);
    },
    [onProfilePicChange]
  );

  return (
    <Box textAlign="center">
      <Avatar
        alt="Profile Picture"
        src={profilePic || "/placeholder-avatar.png"}
        sx={{ width: 150, height: 150, m: "auto", border: "4px solid rgba(0, 0, 0, 0.1)" }}
      />
      <input
        accept={ALLOWED_FILE_TYPES.join(",")}
        style={{ display: "none" }}
        id="profile-pic-upload"
        type="file"
        onChange={handleProfilePicChange}
      />
      <label htmlFor="profile-pic-upload">
        <Button
          variant="contained"
          component="span"
          color="info"
          startIcon={<CloudUploadIcon />}
          sx={{ mt: 2 }}
        >
          Update Photo
        </Button>
      </label>
      <Typography variant="caption" display="block" sx={{ mt: 1, color: "text.secondary" }}>
        Recommended: 400x400px, Max 5MB
      </Typography>
    </Box>
  );
};

export default ProfilePicture;
