import React, { useEffect, useMemo, useCallback, useState } from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "examples/Tables/Table";
import VuiButton from "components/VuiButton";
import useApi from "shared/hooks/UseApi";
import { getMeeting, joiningWithJotter } from "shared/services/ApiService/ApiService";
import Link from "@mui/material/Link";
import MAAttendeeDropdown from "components/MAAttendeeDropdown/MAAttendeeDropdown";
import { Switch } from "@mui/material";
import useAuth from "shared/hooks/UseAuth";
import { useDispatch, useSelector } from "react-redux";
import { setNextMeeting } from "shared/Slices/MeetingSlice";
import MATruncatedText from "components/MATrucatedText/MATruncatedText";
import CircularProgress from "@mui/material/CircularProgress";
import MAScrollbar from "components/MAScrollbar/MAScrollbar";

function NextMeetingTable() {
  const dispatch = useDispatch();
  const meetings = useSelector((state) => state.meetings.nextMeeting);
  const { loading, error, callApi: callMeetingApi } = useApi(getMeeting);
  const { callApi: callJoiningWithJotterApi } = useApi(joiningWithJotter);
  const { authProvider } = useAuth();

  const [refreshKey, setRefreshKey] = useState(0); // State to trigger refresh

  const meetingColumns = useMemo(
    () => [
      { name: "Meeting Name", align: "center" },
      { name: "Organizer", align: "center" },
      { name: "Start Time", align: "center" },
      { name: "End Time", align: "center" },
      { name: "Duration", align: "center" },
      { name: "Attendees", align: "center" },
      { name: "Join Meeting", align: "center" },
      { name: "Joining with Jotter", align: "center" },
    ],
    []
  );

  const parseAttendees = useCallback((attendees) => {
    if (Array.isArray(attendees)) return attendees;
    if (typeof attendees === "string") {
      try {
        return JSON.parse(attendees);
      } catch (e) {
        console.error("Failed to parse attendees string:", e);
        return [];
      }
    }
    return [];
  }, []);

  const formatMeetingData = useCallback(
    (meetings) => {
      return meetings.map((meeting) => {
        const startTime = new Date(authProvider === "outlook" ? meeting.start_time : meeting.start);
        const endTime = new Date(authProvider === "outlook" ? meeting.end_time : meeting.end);
        const duration = meeting.duration || `${(endTime - startTime) / 60000} minutes`;

        const formattedStartTime = startTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const formattedEndTime = endTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const attendees = parseAttendees(meeting.attendees);
        const organizer =
          authProvider === "outlook"
            ? meeting.organizer
            : attendees.find((attendee) => attendee.organizer)?.email || "N/A";

        const meetingLink = authProvider === "outlook" ? meeting.join_url : meeting.hangoutLink;

        return {
          "Meeting Name": <MATruncatedText text={meeting.summary || "No title"} />,
          Organizer: <MATruncatedText text={organizer || "No title"} />,
          "Start Time": formattedStartTime,
          "End Time": formattedEndTime,
          Duration: duration,
          Attendees:
            attendees.length > 0 ? <MAAttendeeDropdown attendees={attendees} /> : "No attendees",
          "Join Meeting": meetingLink ? (
            <VuiButton color="info">
              <Link href={meetingLink} color="inherit" target="_blank" rel="noopener noreferrer">
                Join Meeting
              </Link>
            </VuiButton>
          ) : (
            "N/A"
          ),
          "Joining with Jotter": (
            <Switch
              checked={meeting.join_with_jotter}
              onChange={() =>
                handleToggle(meeting.ical_uid || meeting.eventId, !meeting.join_with_jotter)
              }
              name="joiningWithJotter"
              color="primary"
            />
          ),
        };
      });
    },
    [authProvider, parseAttendees]
  );

  const handleToggle = useCallback(
    (id, newValue) => {
      callJoiningWithJotterApi(id, newValue, authProvider)
        .then((response) => {
          // console.log("in callJoiningWithJotterApi", response);
          if (response.status == 200) {
            dispatch(
              setNextMeeting(
                meetings.map((meeting) =>
                  meeting.ical_uid === id || meeting.eventId === id
                    ? { ...meeting, join_with_jotter: newValue }
                    : meeting
                )
              )
            );
          }
        })
        .catch((error) => {
          console.error("Error toggling jotter:", error);
        });
    },
    [dispatch, meetings, authProvider, callJoiningWithJotterApi]
  );

  const handleUpdate = useCallback(async () => {
    const response = await callMeetingApi();
    if (response?.data?.meetings) {
      dispatch(setNextMeeting(response.data.meetings));
    }
  }, [callMeetingApi, dispatch]);

  // Effect to load meetings initially and on refresh
  useEffect(() => {
    handleUpdate();
  }, [handleUpdate, refreshKey]); // Add refreshKey to the dependency array

  const formattedMeetings = useMemo(
    () => formatMeetingData(meetings),
    [meetings, formatMeetingData]
  );

  return (
    <VuiBox>
      {/* Table Header with Title and Refresh Button */}
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <VuiTypography variant="h5" color="light">
          Upcoming Meetings Table
        </VuiTypography>
        <VuiButton
          variant="outlined"
          color="success"
          disabled={loading}
          onClick={() => setRefreshKey((prev) => prev + 1)} // Increment refreshKey to trigger update
        >
          Refresh
        </VuiButton>
      </VuiBox>

      {/* Loading and Error Handling */}
      {loading ? (
        <VuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="20rem"
        >
          <CircularProgress color="primary" size={110} />
          <VuiTypography variant="body1" color="white" mt={5}>
            Loading...
          </VuiTypography>
        </VuiBox>
      ) : error ? (
        <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <VuiTypography color="error" variant="h6">
            Failed to load data. Please try again.
          </VuiTypography>
        </VuiBox>
      ) : (
        <MAScrollbar>
          {meetings.length > 0 ? (
            <Table columns={meetingColumns} rows={formattedMeetings} />
          ) : (
            <VuiTypography variant="body1" color="error">
              No meetings found.
            </VuiTypography>
          )}
        </MAScrollbar>
      )}
    </VuiBox>
  );
}

export default NextMeetingTable;
