import React, { useState, useCallback } from "react";
import { Box, Grid, Typography, Button, Snackbar, Alert, CircularProgress } from "@mui/material";
import { StyledCard, StyledSection } from "../Styles";
import PasswordSettings from "./Components/PasswordSettings";
import TwoFactorAuthentication from "./Components/TwoFactorAuthentication";
import RecoveryEmail from "./Components/RecoveryEmail";
import SessionTimeout from "./Components/SessionTimeout";
import LoginNotifications from "./Components/LoginNotifications";

const AccountSettings = ({ onSave }) => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    twoFactorEnabled: false,
    recoveryEmail: "",
    sessionTimeout: "30",
    loginNotifications: true,
  });

  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleInputChange = useCallback((event) => {
    const { name, value, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: event.target.type === "checkbox" ? checked : value,
    }));
  }, []);

  const handleSave = async () => {
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1500));
      if (onSave) {
        await onSave(formData);
      }
      setNotification({
        open: true,
        message: "Security settings updated successfully",
        severity: "success",
      });
    } catch (error) {
      setNotification({
        open: true,
        message: error.message || "Error updating settings",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledCard>
      <Box display="flex" alignItems="center" mb={3}>
        <Typography variant="h5" component="h1">
          Security Settings
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PasswordSettings formData={formData} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={12}>
          <TwoFactorAuthentication
            twoFactorEnabled={formData.twoFactorEnabled}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <RecoveryEmail recoveryEmail={formData.recoveryEmail} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={12}>
          <SessionTimeout sessionTimeout={formData.sessionTimeout} onChange={handleInputChange} />
        </Grid>
        <Grid item xs={12}>
          <LoginNotifications
            loginNotifications={formData.loginNotifications}
            onChange={handleInputChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? "Saving..." : "Save Changes"}
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert
          onClose={() => setNotification({ ...notification, open: false })}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </StyledCard>
  );
};

export default AccountSettings;
