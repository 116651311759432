import React, { useState } from "react";
import { Modal } from "@mui/material";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";

export const SharedDriveUpload = () => {
  const [open, setOpen] = useState(false);
  const [driveLink, setDriveLink] = useState(""); // Changed to driveLink

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setDriveLink(""); // Clear the input when closing
    setOpen(false);
  };

  const handleDriveLinkChange = (event) => {
    setDriveLink(event.target.value); // Updated to handle driveLink
  };

  const handleDriveConnect = () => {
    if (driveLink) {
      // console.log("Connecting to shared drive:", driveLink);
      handleClose();
    }
  };

  return (
    <VuiBox>
      <VuiButton
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{
          color: "white",
          height: "3rem",
          width: "10rem",
          textAlign: "center",
          circular: true,
          boxShadow: 2,
          "&:hover": { boxShadow: 4 },
        }}
      >
        Shared Drive
      </VuiButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="shared-drive-modal"
        aria-describedby="modal-for-shared-drive-connection"
      >
        <VuiBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "#2D3250", // Dark background to match previous components
            borderRadius: "10px",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            p: 4,
          }}
        >
          <VuiTypography
            id="shared-drive-modal"
            variant="h6"
            textAlign="center"
            mb={2}
            color="white" // White text for contrast with dark background
          >
            Connect to Shared Drive
          </VuiTypography>

          <VuiInput
            type="url" // Changed to accept URL
            placeholder="Enter shared drive link"
            value={driveLink} // Updated variable name
            onChange={handleDriveLinkChange} // Updated handler
            sx={{ my: "2rem", display: "block" }}
          />

          <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <VuiButton
              variant="contained"
              color="success"
              onClick={handleDriveConnect}
              disabled={!driveLink} // Enable only if driveLink is filled
            >
              Connect
            </VuiButton>

            <VuiButton variant="contained" color="error" onClick={handleClose} sx={{ ml: 2 }}>
              Cancel
            </VuiButton>
          </VuiBox>
        </VuiBox>
      </Modal>
    </VuiBox>
  );
};
