import { Link } from "react-router-dom";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";
import bgSignIn from "assets/images/signInImage.png"; // Add your own image
import useAuth from "shared/hooks/UseAuth";
import CoverLayout from "layouts/authentication/components/CoverLayout";

function SignOut() {
  const { handleLogout } = useAuth();

  const handleSignOut = () => {
    // Show confirmation dialog
    const confirmed = window.confirm("Are you sure you want to sign out?");
    if (confirmed) {
      handleLogout(); // Proceed with logout if confirmed
    }
  };

  return (
    <CoverLayout
      title="See you soon!"
      color="white"
      description="You have been signed out. We hope to see you again!"
      premotto="Thank you for using our service"
      motto="MEETARISE"
      image={bgSignIn}
      cardContent
    >
      <GradientBorder minWidth="100%" maxWidth="100%">
        <VuiBox
          sx={{
            borderRadius: "inherit",
            p: "45px",
            backgroundColor: "secondary.focus",
          }}
        >
          <VuiTypography color="white" fontWeight="bold" textAlign="center" mb="24px">
            Sign Out Confirmation
          </VuiTypography>

          <VuiTypography color="text" textAlign="center" mb="30px">
            Are you sure you want to sign out?
          </VuiTypography>

          <VuiBox mt={4} mb={1}>
            <VuiButton color="info" fullWidth onClick={handleSignOut}>
              SIGN OUT
            </VuiButton>
          </VuiBox>

          <VuiBox mt={3} textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              Want to go back?{" "}
              <VuiTypography
                component={Link}
                to="/home"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                Go to Home
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </CoverLayout>
  );
}

export default SignOut;
