import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  setToken,
  removeToken,
  setAuthProvider,
  removeAuthProvider,
} from "shared/Slices/AuthSlice";
import { setUser, removeUser } from "shared/Slices/userSlice";
import { ssoGooglelogin, ssoOutlooklogin, logout } from "shared/services/ApiService/ApiService";
import useApi from "shared/hooks/UseApi";
import { googleLogout } from "@react-oauth/google";
import { useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { clearChat } from "shared/Slices/chatHistorySlice";

const useAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { instance } = useMsal();
  const token = useSelector((state) => state.auth.token) || Cookies.get("access_token");
  const authProvider =
    useSelector((state) => state.auth.authProvider) || Cookies.get("authProvider");

  const [loginError, setLoginError] = useState("");

  const {
    loading: googleLoading,
    error: googleError,
    callApi: callGoogleApi,
  } = useApi(ssoGooglelogin);
  const {
    loading: outlookLoading,
    error: outlookError,
    callApi: callOutlookApi,
  } = useApi(ssoOutlooklogin);
  const { callApi: callLogoutApi } = useApi(logout);

  const logMessage = (message) => console.log(message); // Could enhance with a logging library

  const updateTokenAndStoreInCookies = useCallback(
    (newToken) => {
      if (newToken) {
        dispatch(setToken(newToken));
        logMessage("Token set successfully");
      } else {
        dispatch(removeToken());
        dispatch(removeUser());
        dispatch(clearChat());
        logMessage("Token removed successfully");
      }
    },
    [dispatch]
  );

  const updateAuthProvider = useCallback(
    (provider) => {
      if (provider) {
        dispatch(setAuthProvider(provider));
        logMessage("Auth provider set to " + provider);
      } else {
        dispatch(removeAuthProvider());
        logMessage("Auth provider removed");
      }
    },
    [dispatch]
  );

  const handleLoginSuccess = useCallback(
    (res, provider) => {
      if (res?.data?.user) {
        dispatch(setUser(res.data.user));
        updateAuthProvider(provider);
      }

      if (res?.data?.access_token) {
        updateTokenAndStoreInCookies(res.data.access_token);
        history.push("/home");
      } else {
        throw new Error("No token received from server");
      }
    },
    [dispatch, history, updateAuthProvider, updateTokenAndStoreInCookies]
  );

  const handleGoogleLoginSuccess = useCallback(
    async (credentialResponse) => {
      const { code } = credentialResponse;
      try {
        const res = await callGoogleApi(code);
        // logMessage("SSO Google login response: " + JSON.stringify(res));
        if (res.status == 200) {
          handleLoginSuccess(res, "google");
        } else {
          setLoginError("Google Login failed");
        }
      } catch (err) {
        logMessage("Error during Google login: " + err);
        setLoginError("Google Login failed: " + (err.message || "Unknown error"));
      }
    },
    [callGoogleApi, handleLoginSuccess]
  );

  const handleOutlookLogin = useCallback(
    async (code) => {
      try {
        const res = await callOutlookApi(code);
        // logMessage("Outlook login response: " + JSON.stringify(res));
        if (res.status == 200) {
          handleLoginSuccess(res, "outlook");
        } else {
          setLoginError("Outlook Login failed");
        }
      } catch (e) {
        setLoginError("Outlook Login failed: " + (e.message || "Unknown error"));
      }
    },
    [callOutlookApi, handleLoginSuccess]
  );

  const handleLogout = useCallback(async () => {
    try {
      const response = await callLogoutApi(); // Call the logout function and await response

      if (response.status === 200) {
        if (authProvider === "google") {
          logMessage("Google logout initiated");
          googleLogout();
        } else if (authProvider === "outlook") {
          instance.logoutPopup().catch((err) => {
            console.error("Outlook logout failed:", err);
          });
          logMessage("Outlook logout initiated");
        }

        // Clear token-related state and cookies
        updateTokenAndStoreInCookies(null);
        updateAuthProvider(null);

        // Update Redux store to remove user data
        dispatch(removeUser());

        // Redirect to sign-in page
        history.push("/");
      } else {
        console.error("Logout failed with response:", response);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }, [authProvider, updateTokenAndStoreInCookies, updateAuthProvider]);

  const checkAuth = useCallback(() => {
    if (token) return; // Already authenticated

    if (
      !token &&
      !["/authentication/sign-up", "/", "/api/login/outlook/"].includes(history.location.pathname)
    ) {
      logMessage("No token found, logging out");
      handleLogout();
    }
  }, [token, handleLogout, history]);

  return {
    loading: googleLoading || outlookLoading,
    error: loginError || googleError || outlookError,
    handleGoogleLoginSuccess,
    handleOutlookLogin,
    handleLogout,
    checkAuth,
    isAuthenticated: !!token,
    authProvider: authProvider,
  };
};

export default useAuth;
