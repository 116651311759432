import React from "react";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMore, Business } from "@mui/icons-material";
import VuiBox from "components/VuiBox";

const GeneralSettings = ({ generalSettings, handleChange }) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="general-settings-content"
        id="general-settings-header"
      >
        <VuiBox sx={{ display: "flex", alignItems: "center" }}>
          <Business sx={{ mr: 2 }} />
          <Typography variant="h6">General Settings</Typography>
        </VuiBox>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Company Name"
              variant="outlined"
              value={generalSettings.companyName}
              onChange={handleChange("companyName")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Timezone</InputLabel>
              <Select
                value={generalSettings.timezone}
                onChange={handleChange("timezone")}
                label="Timezone"
              >
                <MenuItem value="UTC">UTC</MenuItem>
                <MenuItem value="EST">EST</MenuItem>
                <MenuItem value="PST">PST</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Data Retention (days)"
              type="number"
              variant="outlined"
              value={generalSettings.dataRetention}
              onChange={handleChange("dataRetention")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default GeneralSettings;
