import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Email as EmailIcon } from "@mui/icons-material";
import { StyledSection } from "../../Styles";

const RecoveryEmail = ({ recoveryEmail, onChange }) => {
  return (
    <StyledSection>
      <Box display="flex" alignItems="center" mb={2}>
        <EmailIcon sx={{ mr: 1, color: "primary.main" }} />
        <Typography variant="h6">Recovery Email</Typography>
      </Box>
      <TextField
        fullWidth
        name="recoveryEmail"
        label="Recovery Email"
        value={recoveryEmail}
        onChange={onChange}
      />
      <Typography variant="body2" color="text.secondary" mt={1}>
        Your recovery email will be used to help you regain access to your account if you forget
        your password.
      </Typography>
    </StyledSection>
  );
};

export default RecoveryEmail;
