import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiBox from "components/VuiBox"; // VuiBox for layout control
import useApi from "shared/hooks/UseApi";
import { joinMeeting } from "shared/services/ApiService/ApiService";

const MADialog = forwardRef(function MADialog({ title, open, onClose, onConnect }, ref) {
  const [meetingTitle, setMeetingTitle] = useState("");
  const [meetingUrl, setMeetingUrl] = useState("");
  const [validationError, setValidationError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { loading, error, callApi } = useApi(joinMeeting);

  // Expose getInputValues method to parent components
  useImperativeHandle(ref, () => ({
    getInputValues: () => ({ meetingTitle, meetingUrl }),
  }));

  // Validation helper for URLs
  const isValidUrl = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])?)\\.)+([a-z]{2,}|[a-z\\d-]{2,})|" + // domain name
        "localhost|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|\\[?[a-f\\d:]+\\])" + // IP Address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?" + // fragment locator
        "(.*)?$", // allows everything after fragment
      "i"
    );
    return !!urlPattern.test(url);
  };
  // Handle changes in the meeting title input
  const handleTitleChange = (event) => {
    setMeetingTitle(event.target.value);
    setValidationError("");
    setSuccessMessage("");
  };

  // Handle changes in the meeting URL input
  const handleUrlChange = (event) => {
    setMeetingUrl(event.target.value);
    setValidationError("");
    setSuccessMessage("");
  };

  // Handle the connect action
  const handleConnect = async () => {
    // Validate input fields
    if (!meetingTitle.trim()) {
      setValidationError("Meeting title is required.");
      return;
    }

    if (!meetingUrl.trim()) {
      setValidationError("Meeting URL is required.");
      return;
    }

    if (!isValidUrl(meetingUrl)) {
      setValidationError("Invalid URL. Please enter a valid URL.");
      return;
    }

    try {
      const response = await callApi(meetingTitle, meetingUrl);
      // console.log(response);
      if (response.status == 200) {
        setSuccessMessage("Meeting connected successfully!");
        onConnect({ title: meetingTitle, url: meetingUrl });
        handleClose(); // Close the modal after successful connection
      }
    } catch (err) {
      // Handle API errors if needed
      setValidationError("Failed to connect to the meeting.");
    }
  };

  // Handle closing the modal and clearing all data
  const handleClose = () => {
    onClose();
    setMeetingTitle(""); // Clear meeting title
    setMeetingUrl(""); // Clear meeting URL
    setValidationError(""); // Clear validation errors
    setSuccessMessage(""); // Clear success messages
  };

  // Reset the form when the modal is opened
  useEffect(() => {
    if (open) {
      setMeetingTitle("");
      setMeetingUrl("");
      setValidationError("");
      setSuccessMessage("");
    }
  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          position: "relative",
          backgroundColor: "#2D3250", // Dark background for dialog
          borderRadius: "10px",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      {/* Dialog Header */}
      <VuiBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
        <VuiTypography variant="h5" color="white">
          {title}
        </VuiTypography>
        <IconButton edge="end" color="white" onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </VuiBox>

      {/* Dialog Content */}
      <VuiBox px={2} pb={2}>
        {/* Meeting Title Input */}
        <VuiBox mb={2}>
          <VuiInput
            type="text"
            value={meetingTitle}
            variant="outlined"
            color="white"
            onChange={handleTitleChange}
            error={!!validationError || !!error}
            success={!!successMessage}
            fullWidth
            placeholder="Enter Meeting Title"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: validationError || error ? "red" : "default",
                },
                "&:hover fieldset": {
                  borderColor: "blue",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "green",
                },
              },
            }}
          />
        </VuiBox>

        {/* Meeting URL Input */}
        <VuiBox mb={2}>
          <VuiInput
            type="url"
            value={meetingUrl}
            variant="outlined"
            color="white"
            onChange={handleUrlChange}
            error={!!validationError || !!error}
            success={!!successMessage}
            fullWidth
            placeholder="Enter Meeting URL"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: validationError || error ? "red" : "default",
                },
                "&:hover fieldset": {
                  borderColor: "blue",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "green",
                },
              },
            }}
          />
        </VuiBox>

        {/* Connect Button */}
        <VuiButton
          onClick={handleConnect}
          variant="contained"
          color="success" // Use success color for variant
          disabled={loading}
          sx={{
            width: "100%",
            height: "3rem",
            textAlign: "center",
            color: "white", // Set the button text color to white
            borderRadius: "0.5rem",
            boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.3)",
          }}
        >
          {loading ? "Connecting..." : "Connect"}
        </VuiButton>

        {/* Display validation or API error message */}
        {(validationError || error) && (
          <VuiBox mt={2}>
            <VuiTypography color="error">{validationError || error}</VuiTypography>
          </VuiBox>
        )}

        {/* Display success message */}
        {successMessage && (
          <VuiBox mt={2}>
            <VuiTypography color="success.main">{successMessage}</VuiTypography>
          </VuiBox>
        )}
      </VuiBox>
    </Dialog>
  );
});

MADialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConnect: PropTypes.func.isRequired,
};

export default MADialog;
