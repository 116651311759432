import axios from "axios";
import Cookies from "js-cookie";
import { ENDPOINTS } from "./EndPoint";
import config from "shared/Config/config";

// Constants
const CSRF_HEADER = "X-CSRFToken";
const COOKIE_OPTIONS = { expires: 7, secure: true, sameSite: "Strict" };

// Utility Functions
const getCsrfToken = () => {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith("csrftoken="))
    ?.split("=")[1];
};

const handleTokenError = (error) => {
  console.error("API call error:", error);
  // Here, you can also handle specific error responses if needed
  return { success: false, error: error.response ? error.response.data : "An error occurred" };
};

// Create Axios instance
const api = axios.create({
  baseURL: config.BASE_URL,
  timeout: 60000,
  withCredentials: true,
});

// Request interceptor to attach access token
api.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    config.headers[CSRF_HEADER] = getCsrfToken();
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 errors and refresh tokens
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const refreshToken = Cookies.get("refresh_token");

//       if (refreshToken) {
//         try {
//           const response = await api.post(ENDPOINTS.REFRESH_TOKEN, { refresh: refreshToken });
//           // console.log("refresh", response);
//           const newAccessToken = response.data.access;
//           Cookies.set("access_token", newAccessToken, COOKIE_OPTIONS);
//           originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
//           return api(originalRequest);
//         } catch (refreshError) {
//           console.error("Failed to refresh token:", refreshError);
//           Cookies.remove("access_token");
//           Cookies.remove("refresh_token");
//           window.location.href = "/login"; // Redirect to login
//         }
//       }
//     }
//     return Promise.reject(error);
//   }
// );

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check for server/network issues and alert the user
    if (!error.response) {
      alert(
        "A server/network error occurred. " + "CORS might be a problem. Please try again later."
      );
      return Promise.reject(error);
    }

    // Redirect to login if refresh token endpoint fails with 401
    if (error.response.status === 401 && originalRequest.url === ENDPOINTS.REFRESH_TOKEN) {
      window.location.href = "/";
      return Promise.reject(error);
    }

    // Handle invalid token and attempt refresh if possible
    if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
      const refreshToken = Cookies.get("refresh_token");

      if (refreshToken) {
        // Decode the refresh token to check expiration
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));
        const now = Math.ceil(Date.now() / 1000);

        // Check if refresh token is still valid
        if (tokenParts.exp > now) {
          try {
            const response = await api.post(ENDPOINTS.REFRESH_TOKEN, {
              refresh: refreshToken,
            });

            const newAccessToken = response.data.access;
            const newRefreshToken = response.data.refresh;

            // Store the new tokens in cookies
            Cookies.set("access_token", newAccessToken, { secure: true, sameSite: "Strict" });
            Cookies.set("refresh_token", newRefreshToken, { secure: true, sameSite: "Strict" });

            // Update the default Authorization header with the new access token
            api.defaults.headers["Authorization"] = "Bearer " + newAccessToken;

            // Retry the original request with the new access token
            originalRequest.headers["Authorization"] = "Bearer " + newAccessToken;
            return api(originalRequest);
          } catch (refreshError) {
            console.error("Error refreshing token:", refreshError);
            // Redirect to login if token refresh fails
            Cookies.remove("access_token");
            Cookies.remove("refresh_token");
            window.location.href = "/";
          }
        } else {
          console.log("Refresh token has expired");
          // Remove expired tokens and redirect to login
          Cookies.remove("access_token");
          Cookies.remove("refresh_token");
          window.location.href = "/";
        }
      } else {
        console.log("No refresh token available");
        window.location.href = "/";
      }
    }

    return Promise.reject(error);
  }
);

// Generic API call function
const apiCall = async (method, url, data = null, config = {}, abortController) => {
  try {
    const response = await api({
      method,
      url,
      data,
      signal: abortController.signal,
      ...config,
    });
    // console.log("response", response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    return handleTokenError(error);
  }
};

// Specific API calls
export const ssoGooglelogin = async (code, abortController) => {
  try {
    const response = await apiCall(
      "post",
      ENDPOINTS.SSO_GOOGLE_LOGIN,
      { code },
      {},
      abortController
    );
    if (response.status == 200) {
      const { access_token, refresh_token, user } = response.data;
      if (user) Cookies.set("userData", JSON.stringify(user), COOKIE_OPTIONS);
      if (refresh_token) Cookies.set("refresh_token", refresh_token, COOKIE_OPTIONS);
      if (access_token) Cookies.set("access_token", access_token, COOKIE_OPTIONS);
    }
    return response;
  } catch (error) {
    return handleTokenError(error);
  }
};
export const ssoOutlooklogin = async (code, abortController) => {
  try {
    // console.log("sso outlook login");
    const response = await apiCall(
      "post",
      ENDPOINTS.SSO_OUTLOOK_LOGIN,
      { code },
      {},
      abortController
    );
    if (response.status == 200) {
      const { access_token, refresh_token, user } = response.data;
      if (user) Cookies.set("userData", JSON.stringify(user), COOKIE_OPTIONS);
      if (refresh_token) Cookies.set("refresh_token", refresh_token, COOKIE_OPTIONS);
      if (access_token) Cookies.set("access_token", access_token, COOKIE_OPTIONS);
    }
    return response; // Return success status and data
  } catch (error) {
    return handleTokenError(error);
  }
};
export const logout = async (abortController) => {
  try {
    console.log("logout");
    // Send the refresh token in the request body to blacklist it
    const refresh_token = Cookies.get("refresh_token");
    // console.log("refresh", refresh);
    const response = await apiCall(
      "post",
      ENDPOINTS.LOGOUT,
      { refresh: refresh_token },
      {},
      abortController
    );

    // Clear cookies after successful logout
    console.log(response);
    if (response.status == 200) {
      Cookies.remove("access_token");
      Cookies.remove("refresh_token");
    }
    return response;
  } catch (error) {
    console.error("Error logging out:", error);
    throw error; // Re-throw error to handle it in handleLogout if needed
  }
};
export const joinMeeting = async (meetingTitle, meetingUrl, abortController) => {
  const userCookieData = JSON.parse(Cookies.get("userData") || "{}");
  return apiCall(
    "post",
    ENDPOINTS.JOIN_MEETING,
    { title: meetingTitle, url: meetingUrl, email: userCookieData?.email },
    {},
    abortController
  );
};

export const getMeeting = async (abortController) => {
  const authProvider = Cookies.get("authProvider");
  if (authProvider == "google") {
    return apiCall("get", ENDPOINTS.GET_MEETINGS, {}, {}, abortController);
  } else if (authProvider == "outlook") {
    return apiCall("get", ENDPOINTS.OUTLOOK_MEETINGS, {}, {}, abortController);
  }
};
export const getAnalytics1 = async (abortController) => {
  return apiCall("get", ENDPOINTS.ANALYTICS1, {}, {}, abortController);
};
export const getAnalytics2 = async (abortController) => {
  return apiCall("get", ENDPOINTS.ANALYTICS2, {}, {}, abortController);
};
export const getAnalytics1Stats = async (abortController) => {
  return apiCall("get", ENDPOINTS.ANALYTICS1_STATS, {}, {}, abortController);
};
export const getUserQuota = async (abortController) => {
  return apiCall("get", ENDPOINTS.USER_QUOTE, {}, {}, abortController);
};
// Function to update the action item
export const updateActionItem = async (actionItemId, data, abortController) => {
  return apiCall(
    "patch",
    `${ENDPOINTS.ACTION_ITEM_UPDATE}${actionItemId}/update/`,
    data,
    {},
    abortController
  );
};

export const pastMeeting = async (abortController) => {
  return apiCall("get", ENDPOINTS.PAST_MEETING, null, {}, abortController);
};
export const scheduler = async (abortController) => {
  // console.log("Schedular");
  const authProvider = Cookies.get("authProvider");
  // console.log(authProvider);
  if (authProvider == "google") {
    return apiCall("get", ENDPOINTS.SCHEDULER, null, {}, abortController);
  } else if (authProvider == "outlook") {
    return apiCall("get", ENDPOINTS.OUTLOOK_SCHEDULER, null, {}, abortController);
  }
};

export const upload = async (formData, abortController) => {
  return apiCall(
    "post",
    ENDPOINTS.UPLOAD,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Ensure correct content type for file upload
      },
    },
    abortController
  );
};
// query
export const query = async (query, abortController) => {
  return apiCall("post", ENDPOINTS.QUERY, { query }, {}, abortController);
};
export const getFileList = async (abortController) => {
  return apiCall("get", ENDPOINTS.FILE_LIST, {}, {}, abortController);
};
export const deleteFileList = async (abortController) => {
  return apiCall("delete", ENDPOINTS.DELETE_FILE_LIST, {}, {}, abortController);
};
export const joiningWithJotter = async (id, join_with_jotter, provider, abortController) => {
  // console.log("joiningWithJotter", id, join_with_jotter, provider);
  return apiCall(
    "post",
    ENDPOINTS.JOIN_WITH_JOTTER,
    { id: id, join_with_jotter: join_with_jotter, provider: provider },
    {},
    abortController
  );
};

// Export the Axios instance for direct use if needed
export { api };
