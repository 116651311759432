import React, { useState } from "react";
import { Modal, Snackbar, CircularProgress } from "@mui/material";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import useApi from "shared/hooks/UseApi";
import { upload } from "shared/services/ApiService/ApiService";

export const FileUpload = () => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadResults, setUploadResults] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const { loading, callApi } = useApi(upload);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSnackbarOpen(false);
    setOpen(false);
    setFile(null);
    setUploadResults([]);
    setUploadProgress(0);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setUploadProgress(0);
    } else {
      setSnackbarMessage("Please select a valid PDF file.");
      setSnackbarOpen(true);
      setFile(null);
    }
  };

  const handleFileUpload = async () => {
    if (!file) return;

    const results = [];
    const formData = new FormData();
    formData.append("file", file);

    try {
      // Simulate upload progress
      const progressInterval = setInterval(() => {
        setUploadProgress((prev) => {
          if (prev >= 90) {
            clearInterval(progressInterval);
            return 90;
          }
          return prev + 10;
        });
      }, 300);

      const response = await callApi(formData);
      // console.log("hello", response);
      if (response.status == 200) {
        clearInterval(progressInterval);
        setUploadProgress(100);
        if (response?.data?.message) {
          results.push({
            fileName: file.name,
            status: "success",
            message: "Uploaded!",
          });
          setSnackbarMessage(`${file.name} uploaded successfully.`);
          setSnackbarOpen(true);
          setUploadResults(results);

          setFile(null);
          setTimeout(() => {
            handleClose();
          }, 2000);
        } else if (response?.data?.error) {
          results.push({
            fileName: file.name,
            status: "error",
            message: "Failed!",
          });
          setSnackbarMessage("File upload failed: " + file.name);
          setSnackbarOpen(true);
          setUploadResults(results);
          setUploadProgress(0);
        }
      }
    } catch (error) {
      setUploadProgress(0);
      alert("File upload failed. Error: " + error.message);
      console.error("Error uploading file:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <VuiBox>
      <VuiButton
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{
          color: "white",
          height: "3rem",
          width: "10rem",
          textAlign: "center",
          circular: true,
          boxShadow: 2,
          "&:hover": { boxShadow: 4 },
        }}
      >
        File
      </VuiButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="file-upload-modal"
        aria-describedby="modal-for-file-upload"
      >
        <VuiBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "#2D3250",
            borderRadius: "10px",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            p: 4,
          }}
        >
          <VuiTypography
            id="file-upload-modal"
            variant="h6"
            mb={2}
            textAlign="center"
            color="white"
          >
            Upload File
          </VuiTypography>

          <VuiInput
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
            sx={{ my: "2rem", display: "block" }}
            disabled={loading}
          />

          {/* Progress Indicator */}
          {(loading || uploadProgress > 0) && (
            <VuiBox sx={{ textAlign: "center", my: 2 }}>
              <CircularProgress
                variant="determinate"
                value={uploadProgress}
                size={60}
                thickness={4}
                sx={{
                  color: "#6366F1", // Indigo color matching your theme
                  "& .MuiCircularProgress-circle": {
                    strokeLinecap: "round",
                  },
                }}
              />
              <VuiTypography variant="body2" color="white" mt={1}>
                {uploadProgress}% Uploaded
              </VuiTypography>
            </VuiBox>
          )}

          <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <VuiButton
              variant="contained"
              color="success"
              onClick={handleFileUpload}
              disabled={!file || loading}
            >
              Upload
            </VuiButton>

            <VuiButton variant="contained" color="error" onClick={handleClose} sx={{ ml: 2 }}>
              Close
            </VuiButton>
          </VuiBox>

          <VuiBox mt={2} sx={{ maxHeight: "150px", overflow: "auto" }}>
            {uploadResults.length > 0 && (
              <VuiTypography variant="body2" color="white" textAlign="center" mb={2}>
                Upload Results:
              </VuiTypography>
            )}
            {uploadResults.map((result, index) => (
              <VuiTypography
                key={index}
                variant="body2"
                color={result.status === "success" ? "success" : "error"}
                textAlign="center"
              >
                {result.fileName} {result.message}
              </VuiTypography>
            ))}
          </VuiBox>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          />
        </VuiBox>
      </Modal>
    </VuiBox>
  );
};
