import React from "react";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import { School } from "@mui/icons-material";
import { Paper } from "@mui/material";

const SkillsInfo = ({ editedData, editMode, handleChange }) => (
  <Paper sx={{ p: 3, backgroundColor: "white" }}>
    <VuiTypography variant="h6" color="dark" mb={2}>
      <School sx={{ mr: 1 }} />
      Skills
    </VuiTypography>
    {editMode ? (
      <VuiInput
        fullWidth
        label="Skills (comma-separated)"
        value={editedData.skills}
        onChange={handleChange("skills")}
        placeholder="e.g., JavaScript, React, Node.js"
      />
    ) : (
      <VuiTypography variant="body2" color="dark">
        {editedData.skills
          ? editedData.skills.split(",").map((skill) => (
              <span
                key={skill}
                style={{
                  marginRight: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "2px 5px",
                  backgroundColor: "#f0f0f0",
                }}
              >
                {skill.trim()}
              </span>
            ))
          : "No skills added"}
      </VuiTypography>
    )}
  </Paper>
);

export default SkillsInfo;
