import React, { Component } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import Chart from "react-apexcharts";

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options || {},
      series: props.series || [],
    };
  }

  componentDidUpdate(prevProps) {
    // Update state if props change
    if (prevProps.options !== this.props.options) {
      this.setState({ options: this.props.options });
    }
    if (prevProps.series !== this.props.series) {
      this.setState({ series: this.props.series });
    }
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="pie"
        width="100%"
        height="100%"
      />
    );
  }
}

export default PieChart;
