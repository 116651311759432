// src/redux/slices/meetingsSlice.js

import { createSlice } from "@reduxjs/toolkit";

const meetingsSlice = createSlice({
  name: "meetings",
  initialState: {
    nextMeeting: [],
    pastMeeting: [],
    Analytics1Meeting: [],
    Analytics2Meeting: [],
  },
  reducers: {
    setNextMeeting(state, action) {
      state.nextMeeting = action.payload;
    },
    setPastMeeting(state, action) {
      state.pastMeeting = action.payload;
    },
    setAnalytics1Meeting(state, action) {
      state.Analytics1Meeting = action.payload;
    },
    setAnalytics2Meeting(state, action) {
      state.Analytics2Meeting = action.payload;
    },
    clearMeetings(state) {
      state.nextMeeting = [];
      state.pastMeeting = [];
      state.Analytics1Meeting = [];
      state.Analytics2Meeting = [];
    },
  },
});

// Export the actions
export const {
  setNextMeeting,
  setPastMeeting,
  setAnalytics1Meeting,
  setAnalytics2Meeting,
  clearMeetings,
} = meetingsSlice.actions;

// Export the reducer
export default meetingsSlice.reducer;
