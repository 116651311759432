import React, { useCallback, useEffect, useState } from "react";
import { Grid, Card, CircularProgress } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Donut from "./Components/Chart/Donut";
import Line from "./Components/Chart/Line";
import Pie from "./Components/Chart/Pie";
import PriorityCards from "./Components/Card/PriorityCard";
import MeetingCard from "./Components/Card/MeetingCard";
import TeamCard from "./Components/Card/TeamCard";
import { useSelector } from "react-redux";
import Cookies from "js-cookie"; // Import js-cookie to manage cookies
import useApi from "shared/hooks/UseApi";
import { getUserQuota } from "shared/services/ApiService/ApiService";
import { Margin } from "@mui/icons-material";

function Home() {
  const userData =
    useSelector((state) => state.user.userData) || JSON.parse(Cookies.get("userData") || "{}");
  const { loading, error, callApi } = useApi(getUserQuota);
  const [data, setData] = useState({
    quota: [1, 1],
    totalStorage: {
      used: 0,
      total: 0,
    },
  });

  const fetchUserQuota = useCallback(async () => {
    const response = await callApi();
    if (response.status === 200) {
      const quotaData = response.data.quota;
      const storageInfo = response.data.storage_info;

      const newQuota = [
        Math.floor(quotaData.used_meeting_quota / 60),
        Math.floor(quotaData.remaining_meeting_quota / 60),
      ];

      const newUsed = {
        used: storageInfo.used_gb,
        total: storageInfo.total_gb,
      };
      setData({ quota: newQuota, totalStorage: newUsed });
    }
  }, [callApi]);

  useEffect(() => {
    fetchUserQuota();
  }, [fetchUserQuota]);

  return (
    <DashboardLayout>
      {/* Navbar */}
      <DashboardNavbar />

      {/* Jotter Dashboard Content */}
      <VuiBox
        py={3}
        sx={{
          minHeight: "100vh",
        }}
      >
        {loading ? (
          <VuiBox
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            sx={{ margin: "auto", minHeight: "100vh", minWidth: "100vw" }}
          >
            <CircularProgress color="primary" size={200} />
            <VuiTypography color="white">Loading....</VuiTypography>
          </VuiBox>
        ) : error ? (
          <VuiTypography>Error during fetching data</VuiTypography>
        ) : (
          <VuiBox mb={3}>
            <Grid container spacing={3}>
              {/* Dashboard Title */}
              <Grid item xs={12}>
                <VuiTypography variant="h2" color="white" fontWeight="bold" mb="5px">
                  Welcome {userData?.given_name},
                </VuiTypography>
              </Grid>

              {/* Join Your Meeting Card and Charts */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <VuiBox p={2} color="white">
                    <Grid container spacing={2}>
                      {/* Donut Charts */}
                      <Grid item xs={12} sm={4}>
                        <Donut title="Jotter Hours" data={data.quota} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Donut title="Querent Query" data={[50, 50]} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Donut title="Brainiac Responses" data={[40, 60]} />
                      </Grid>
                    </Grid>
                  </VuiBox>
                  <VuiBox p={2}>
                    <Grid container spacing={2}>
                      {/* Line Charts */}
                      <Grid item xs={12} sm={6}>
                        <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                          <Line title="Monthly Overview" />
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                          <Line title="Till Date Overview" />
                        </Card>
                      </Grid>
                    </Grid>
                  </VuiBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MeetingCard data={data.totalStorage} />
                </Grid>
              </Grid>

              {/* Additional Charts and Cards Section */}
              <Grid item xs={12}>
                <VuiBox p={2}>
                  <Grid container spacing={2}>
                    {/* Pie Chart */}
                    <Grid item xs={12} sm={4}>
                      <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <Pie />
                      </Card>
                    </Grid>
                    {/* Priority Cards */}
                    <Grid item xs={12} sm={4}>
                      <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <PriorityCards />
                      </Card>
                    </Grid>
                    {/* Team Card */}
                    <Grid item xs={12} sm={4}>
                      <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <TeamCard />
                      </Card>
                    </Grid>
                  </Grid>
                </VuiBox>
              </Grid>
            </Grid>
          </VuiBox>
        )}
      </VuiBox>

      {/* Footer */}
      <Footer />
    </DashboardLayout>
  );
}

export default Home;
