import React, { useState } from "react";
import { Box, CardContent, Typography, Divider, Button, styled } from "@mui/material";
import { StyledCard } from "../Styles";
import GeneralSettings from "./Components/GeneralSettings";
import SecuritySettings from "./Components/SecuritySettings";
import UserManagement from "./Components/UserManagement";
import EmailSettings from "./Components/EmailSettings";

const AdminSettings = () => {
  // State management
  const [generalSettings, setGeneralSettings] = useState({
    companyName: "",
    timezone: "UTC",
    language: "en",
    dataRetention: 365,
  });

  const [securitySettings, setSecuritySettings] = useState({
    twoFactorAuth: false,
    passwordExpiry: 90,
    sessionTimeout: 30,
    ipWhitelisting: false,
  });

  const [userRole, setUserRole] = useState("");

  const [emailSettings, setEmailSettings] = useState({
    smtpServer: "",
    smtpPort: "",
    senderEmail: "",
    enableSSL: false,
  });

  const handleGeneralChange = (name) => (event) => {
    setGeneralSettings({ ...generalSettings, [name]: event.target.value });
  };

  const handleSecurityChange = (name) => (event) => {
    setSecuritySettings({ ...securitySettings, [name]: event.target.checked });
  };

  const handleEmailChange = (name) => (event) => {
    setEmailSettings({ ...emailSettings, [name]: event.target.value });
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Admin Settings
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <GeneralSettings generalSettings={generalSettings} handleChange={handleGeneralChange} />

        <SecuritySettings securitySettings={securitySettings} handleChange={handleSecurityChange} />

        <UserManagement userRole={userRole} setUserRole={setUserRole} />

        <EmailSettings emailSettings={emailSettings} handleChange={handleEmailChange} />

        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary">
            Save Settings
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default AdminSettings;
