import { useState, useCallback } from "react";

const useApi = (apiFunction) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const callApi = useCallback(
    async (...args) => {
      const abortController = new AbortController();
      setLoading(true);
      setError("");
      setSuccessMessage("");

      try {
        const response = await apiFunction(...args, abortController);
        setSuccessMessage(response?.message || "Operation successful");
        return response;
      } catch (err) {
        if (err.name === "AbortError") {
          // console.log("Request was aborted");
        } else {
          // console.error("API call error:", err);
          const errorMessage =
            err.response?.data?.message || "An error occurred. Please try again.";
          setError(errorMessage);
        }
      } finally {
        setLoading(false);
      }
    },
    [apiFunction]
  );

  return {
    loading,
    error,
    successMessage,
    callApi,
  };
};

export default useApi;
