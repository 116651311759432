import * as React from "react";
import { TextField } from "@mui/material";
import VuiBox from "components/VuiBox";

const MADatePicker = ({ value, onChange }) => {
  return (
    <VuiBox>
      <TextField
        type="date"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
        fullWidth
      />
    </VuiBox>
  );
};

export default MADatePicker;
