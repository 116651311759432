import React from "react";
import VuiBox from "components/VuiBox"; // Ensure this path is correct
import VuiTypography from "components/VuiTypography"; // Ensure this path is correct
import Table from "examples/Tables/Table"; // Ensure the correct path to your Table component
import VuiButton from "components/VuiButton"; // If you plan to use buttons, otherwise this import can be removed

const IntentTable = () => {
  // Define your columns
  const columns = [
    { name: "Intent Name", align: "center" },
    { name: "Occurrence", align: "center" },
    { name: "Variation Trend", align: "center" },
  ];

  // Define your rows with example data
  const rows = [
    {
      "Intent Name": "Sales",
      Occurrence: 120,
      "Variation Trend": (
        <VuiButton variant="outlined" color="primary" size="small">
          View trend
        </VuiButton>
      ),
    },
    {
      "Intent Name": "Marketing",
      Occurrence: 95,
      "Variation Trend": (
        <VuiButton variant="outlined" color="primary" size="small">
          View trend
        </VuiButton>
      ),
    },
    {
      "Intent Name": "Product Development",
      Occurrence: 90,
      "Variation Trend": (
        <VuiButton variant="outlined" color="primary" size="small">
          View trend
        </VuiButton>
      ),
    },
  ];

  return (
    <VuiBox sx={{ backgroundColor: "#0f1635", padding: "20px", borderRadius: "1rem" }}>
      <VuiTypography variant="h6" color="white" mb={2}>
        Meeting Insights
      </VuiTypography>
      <Table columns={columns} rows={rows} />
    </VuiBox>
  );
};

export default IntentTable;
