import React from "react";
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMore, Email } from "@mui/icons-material";
import VuiBox from "components/VuiBox";

const EmailSettings = ({ emailSettings, handleChange }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="email-settings-content"
        id="email-settings-header"
      >
        <VuiBox sx={{ display: "flex", alignItems: "center" }}>
          <Email sx={{ mr: 2 }} />
          <Typography variant="h6">Email Configuration</Typography>
        </VuiBox>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="SMTP Server"
              variant="outlined"
              value={emailSettings.smtpServer}
              onChange={handleChange("smtpServer")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="SMTP Port"
              variant="outlined"
              value={emailSettings.smtpPort}
              onChange={handleChange("smtpPort")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Sender Email"
              variant="outlined"
              value={emailSettings.senderEmail}
              onChange={handleChange("senderEmail")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={emailSettings.enableSSL}
                  onChange={handleChange("enableSSL")}
                  color="primary"
                />
              }
              label="Enable SSL"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default EmailSettings;
