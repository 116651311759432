import React, { useState } from "react";
import VuiBox from "components/VuiBox";
import { MenuItem, Select, FormControl, InputLabel, SvgIcon } from "@mui/material";

const ArrowIcon = ({ open }) => (
  <SvgIcon>
    <path d={open ? "M7 15l5-5 5 5H7z" : "M7 10l5 5 5-5H7z"} />
  </SvgIcon>
);

const IndividualDropdowns = () => {
  // Set the initial state to the value of the first dropdown item
  const [user, setUser] = useState("akash@gmail.com"); // Changed to the first item value

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const selectStyles = {
    height: "56px",
    color: "white",
    backgroundColor: "#007bff", // Dark blue background
    "&:hover": {
      backgroundColor: "#0056b3",
      cursor: "pointer",
    },
    "&.Mui-focused": {
      backgroundColor: "#0056b3",
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
  };

  const menuProps = {
    PaperProps: {
      sx: {
        backgroundColor: "#007bff", // Dropdown list background color
        "& .MuiMenuItem-root": {
          color: "white", // Menu item text color
          paddingY: "10px",
          "&:hover": {
            backgroundColor: "#00336e",
            color: "#007bff", // Change text color on hover
          },
          "&.Mui-selected": {
            backgroundColor: "white", // Change selected background to white
            color: "#007bff", // Change selected text color
            "&:hover": {
              backgroundColor: "#00336e", // Hover state for selected item
              color: "#007bff", // Keep selected text color
            },
          },
        },
      },
    },
  };

  const renderDropdown = (id, label, value, setter, items) => (
    <VuiBox sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel
          id={`${id}-label`}
          sx={{
            color: "white", // Input label color
            padding: "0.5rem 0",
            "&.Mui-focused": {
              color: "white", // Focused label color
            },
          }}
          shrink={true}
        >
          {label}
        </InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={value}
          label={label}
          onChange={handleChange(setter)}
          IconComponent={(props) => <ArrowIcon open={props.className.includes("iconOpen")} />}
          sx={selectStyles}
          MenuProps={menuProps}
        >
          {items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </VuiBox>
  );

  return (
    <VuiBox sx={{ display: "flex", gap: 2 }}>
      {renderDropdown("user-select", "User", user, setUser, [
        // Changed id and label to "User"
        { value: "akash@gmail.com", label: "akash@gmail.com" },
        { value: "jotter@gmail.com", label: "jotter@gmail.com" },
        { value: "querent@gmail.com", label: "querent@gmail.com" },
        { value: "brainiac@gmail.com", label: "brainiac@gmail.com" },
      ])}
    </VuiBox>
  );
};

export default IndividualDropdowns;
