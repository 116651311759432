import React, { useRef, useState } from "react";
import VuiButton from "components/VuiButton";
import { buttonStyles } from "../Styles";
import VuiBox from "components/VuiBox";
import Meeting from "../Meeting/Meeting";

const LiveLinkButton = () => {
  const meetingRef = useRef();
  const [meetingTitle, setMeetingTitle] = useState("");
  const handleLiveLinkClick = () => {
    setMeetingTitle("Live Link");
    meetingRef.current.openDialog();
  };

  return (
    <VuiBox>
      <VuiButton onClick={handleLiveLinkClick} {...buttonStyles}>
        Live Link
      </VuiButton>
      <VuiBox>
        <Meeting ref={meetingRef} title={meetingTitle} />
      </VuiBox>
    </VuiBox>
  );
};

export default LiveLinkButton;
