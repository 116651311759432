import React, { useEffect, useMemo } from "react";
import VuiBox from "components/VuiBox";
import LineChart from "examples/Charts/LineCharts/LineChart";
import PropTypes from "prop-types";
import VuiButton from "components/VuiButton";
import useChartError from "shared/hooks/UseCharError";

const EmotionalAnalysisChart = ({ title = "Emotional Analysis" }) => {
  const chartData = useMemo(
    () => ({
      series: [
        {
          name: "Happiness",
          data: [80, 90, 70, 85],
        },
        {
          name: "Sadness",
          data: [20, 10, 30, 15],
        },
        {
          name: "Anger",
          data: [15, 30, 20, 10],
        },
        {
          name: "Surprise",
          data: [50, 60, 40, 70],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          zoom: { enabled: false },
          foreColor: "white",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: ["Q1", "Q2", "Q3", "Q4"],
          labels: {
            style: {
              colors: "white",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "white",
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          theme: "dark",
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          labels: {
            colors: "white",
          },
        },
        title: {
          text: title,
          align: "center",
          style: {
            color: "white",
            fontSize: "18px",
            fontWeight: "bold",
          },
        },
        grid: {
          borderColor: "rgba(255, 255, 255, 0.1)",
        },
      },
    }),
    [title]
  );

  const { error, resetError, updateChart } = useChartError(chartData.options, chartData.series);

  // Update chart data when props change
  useEffect(() => {
    updateChart(chartData.options, chartData.series, "line");
  }, [chartData, updateChart]);

  if (error) {
    return (
      <VuiBox
        sx={{
          backgroundColor: "#0f1635",
          height: "20rem",
          padding: "20px",
          borderRadius: "1rem",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3>Chart Error</h3>
        <p>{error.message}</p>
        <VuiButton
          onClick={resetError}
          variant="contained"
          color="info"
          sx={{
            mt: 3,
            color: "white",
            circular: true,
            cursor: "pointer",
          }}
        >
          Try Again
        </VuiButton>
      </VuiBox>
    );
  }

  return (
    <VuiBox
      sx={{ backgroundColor: "#0f1635", height: "20rem", padding: "20px", borderRadius: "10px" }}
    >
      <LineChart options={chartData.options} series={chartData.series} />
    </VuiBox>
  );
};

EmotionalAnalysisChart.propTypes = {
  title: PropTypes.string,
};

export default EmotionalAnalysisChart;
