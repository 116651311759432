import React, { useState } from "react";
import { Modal } from "@mui/material";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiInput from "components/VuiInput";
import axios from "axios"; // Ensure axios is imported for API requests
import { buttonStyles } from "../Styles";

const UploadButton = () => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFile(null); // Clear file after modal close
    setUploadStatus(""); // Reset status
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Set selected file
  };

  const handleFileUpload = async () => {
    if (!file) return;

    // Prepare file data using FormData
    const formData = new FormData();
    formData.append("file", file); // Append the file for upload

    try {
      // Make the API request to upload the file
      // const response = await axios.post("http://127.0.0.1:8000/api/upload-file/", formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data", // Ensure multipart upload
      //   },
      // });

      // Handle success
      setUploadStatus("File uploaded successfully!");
      // console.log("Upload response:", response.data);
      handleClose(); // Close the modal on success
    } catch (error) {
      // Handle errors
      setUploadStatus("File upload failed.");
      console.error("Error uploading file:", error);
    }
  };

  return (
    <VuiBox>
      <VuiButton variant="contained" color="primary" onClick={handleOpen} {...buttonStyles}>
        Upload
      </VuiButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="file-upload-modal"
        aria-describedby="modal-for-file-upload"
      >
        <VuiBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            backgroundColor: "#2D3250",
            borderRadius: "10px",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            p: 4,
          }}
        >
          <VuiTypography
            id="file-upload-modal"
            variant="h6"
            mb={2}
            textAlign="center"
            color="white"
          >
            Upload File
          </VuiTypography>

          {/* File input */}
          <VuiInput type="file" onChange={handleFileChange} sx={{ my: "2rem", display: "block" }} />

          {/* Upload Button */}
          <VuiBox sx={{ display: "flex", justifyContent: "space-between" }}>
            <VuiButton
              variant="contained"
              color="success"
              onClick={handleFileUpload}
              disabled={!file}
            >
              Upload
            </VuiButton>

            <VuiButton variant="contained" color="error" onClick={handleClose} sx={{ ml: 2 }}>
              Cancel
            </VuiButton>
          </VuiBox>

          {/* Display upload status */}
          {uploadStatus && (
            <VuiTypography mt={2} color={uploadStatus.includes("success") ? "green" : "red"}>
              {uploadStatus}
            </VuiTypography>
          )}
        </VuiBox>
      </Modal>
    </VuiBox>
  );
};

export default UploadButton;
