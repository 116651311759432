import React, { useRef, useState } from "react";
import { Grid, Card, CardHeader } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import MeetingTable from "./components/Table/MeetingTable";
import NextMeetingTable from "./components/NextMeetingTable/NextMeetingTable";
import LiveLinkButton from "./components/Buttons/Live-Link";
import InPersonButton from "./components/Buttons/In-person";
import SchedulerButton from "./components/Buttons/Schedular";
import UploadButton from "./components/Buttons/Upload";

const Jotter = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              <VuiTypography variant="h2" color="white" fontWeight="bold" mb="5px">
                Jotter Dashboard
              </VuiTypography>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Card>
                <CardHeader
                  title={
                    <VuiTypography variant="h5" color="white">
                      Join your Meeting
                    </VuiTypography>
                  }
                />
                <VuiBox
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="center"
                  flexDirection="column"
                  margin="auto"
                >
                  <LiveLinkButton />
                  <SchedulerButton />
                  <InPersonButton />
                  <UploadButton />
                </VuiBox>
              </Card>
            </Grid>

            <Grid item xs={12} sm={9}>
              <Card>
                <VuiBox p={2}>
                  <NextMeetingTable />
                </VuiBox>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <VuiBox p={2}>
                  <MeetingTable />
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>

      <Footer />
    </DashboardLayout>
  );
};

export default Jotter;
