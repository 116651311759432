import React from "react";
import { Box, Grid, TextField } from "@mui/material";
import { Lock as LockIcon } from "@mui/icons-material";
import { StyledSection } from "../../Styles";

import VuiTypography from "components/VuiTypography";

const PasswordSettings = ({ formData, onChange }) => {
  return (
    <StyledSection>
      <Box display="flex" alignItems="center" mb={2}>
        <LockIcon sx={{ mr: 1, color: "primary.main" }} />
        <VuiTypography variant="h6">Password Settings</VuiTypography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="currentPassword"
            label="Current Password"
            type="password"
            value={formData.currentPassword}
            onChange={onChange}
          />
          <TextField
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            value={formData.newPassword}
            onChange={onChange}
          />
          <TextField
            fullWidth
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            value={formData.confirmPassword}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </StyledSection>
  );
};

export default PasswordSettings;
