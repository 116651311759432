import React, { Component } from "react";
import Chart from "react-apexcharts";

class BarChart extends Component {
  constructor(props) {
    super(props);

    // Set initial state based on props
    this.state = {
      options: props.options || {
        chart: {
          id: "apexchart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
        },
      },
      series: props.series || [
        {
          name: "series-1",
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    // Update state if props change
    if (prevProps.options !== this.props.options) {
      this.setState({ options: this.props.options });
    }
    if (prevProps.series !== this.props.series) {
      this.setState({ series: this.props.series });
    }
  }

  render() {
    return (
      <Chart options={this.state.options} series={this.state.series} type="bar" height="100%" />
    );
  }
}

export default BarChart;
