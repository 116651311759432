import React from "react";
import VuiInput from "components/VuiInput";
import VuiTypography from "components/VuiTypography";
import { Work } from "@mui/icons-material";
import { Paper } from "@mui/material";

const ProfessionalInfo = ({ editedData, editMode, handleChange }) => (
  <Paper sx={{ p: 3, backgroundColor: "white" }}>
    <VuiTypography variant="h6" color="dark" mb={2}>
      <Work sx={{ mr: 1 }} />
      Professional Experience
    </VuiTypography>
    {editMode ? (
      <>
        <VuiInput
          fullWidth
          label="Company"
          value={editedData.company}
          onChange={handleChange("company")}
          sx={{ mb: 2 }}
          placeholder="Enter your company"
        />
        <VuiInput
          fullWidth
          label="Position"
          value={editedData.position}
          onChange={handleChange("position")}
          sx={{ mb: 2 }}
          placeholder="Enter your position"
        />
        <VuiInput
          fullWidth
          label="Years of Experience"
          value={editedData.yearsOfExperience}
          onChange={handleChange("yearsOfExperience")}
          placeholder="Enter years of experience"
        />
      </>
    ) : (
      <>
        <VuiTypography variant="body2" color="dark" mb={1}>
          Company: {editedData.company || "Not specified"}
        </VuiTypography>
        <VuiTypography variant="body2" color="dark" mb={1}>
          Position: {editedData.position || "Not specified"}
        </VuiTypography>
        <VuiTypography variant="body2" color="dark">
          Years of Experience: {editedData.yearsOfExperience || "Not specified"}
        </VuiTypography>
      </>
    )}
  </Paper>
);

export default ProfessionalInfo;
