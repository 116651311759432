import React, { useState, useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Tooltip,
  CircularProgress,
  Collapse,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  InsertDriveFile as FileIcon,
  DeleteOutline as DeleteIcon,
  ErrorOutline as ErrorIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import useApi from "shared/hooks/UseApi";
import { getFileList, deleteFileList } from "shared/services/ApiService/ApiService";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";

// Styled components
const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.mode === "dark" ? "#1e1e1e" : "#fff",
    minWidth: 300,
    maxHeight: 500,
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.04)",
  },
}));

const FileDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [showFiles, setShowFiles] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const { callApi: fetchFiles } = useApi(getFileList);
  const { callApi: clearFiles } = useApi(deleteFileList);

  useEffect(() => {
    loadFileList();
  }, []);

  const loadFileList = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetchFiles();
      if (response.status === 200) {
        setFileList(response.data.files);
      }
    } catch (err) {
      setError("Failed to load files. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleClearAll = async () => {
    try {
      setLoading(true);
      setError(null);
      await clearFiles();
      setFileList([]);
      setDeleteDialog(false);
    } catch (err) {
      setError("Failed to clear files. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const toggleFileList = () => setShowFiles((prev) => !prev);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <>
      <Tooltip title="File Options">
        <IconButton onClick={handleOpen} color="inherit" size="large" aria-label="file options">
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* Header */}
        <MenuItem
          onClick={toggleFileList}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "background.default",
          }}
        >
          <VuiTypography variant="h6" fontWeight="bold">
            Files ({fileList.length})
          </VuiTypography>
          {showFiles ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </MenuItem>

        <Divider />

        {/* Error Alert */}
        <Collapse in={Boolean(error)}>
          <Alert
            severity="error"
            icon={<ErrorIcon />}
            onClose={() => setError(null)}
            sx={{ margin: 1 }}
          >
            {error}
          </Alert>
        </Collapse>

        {/* File List */}
        <Collapse in={showFiles}>
          <VuiBox sx={{ maxHeight: 300, overflow: "auto" }}>
            {loading ? (
              <VuiBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  p: 2,
                }}
              >
                <CircularProgress size={24} />
              </VuiBox>
            ) : fileList.length > 0 ? (
              <List disablePadding>
                {fileList.map((file, index) => (
                  <StyledListItem key={index} dense>
                    <ListItemIcon>
                      <FileIcon color="success" />
                    </ListItemIcon>
                    <ListItemText
                      primary={file.file_name}
                      color="white"
                      secondary={
                        <>
                          <VuiTypography component="span" variant="body" color="dark">
                            {formatDate(file.last_updated)}
                          </VuiTypography>
                        </>
                      }
                    />
                  </StyledListItem>
                ))}
              </List>
            ) : (
              <VuiBox
                sx={{
                  p: 2,
                  textAlign: "center",
                  color: "text.secondary",
                }}
              >
                <VuiTypography variant="body2" color="dark">
                  No files available
                </VuiTypography>
              </VuiBox>
            )}
          </VuiBox>
        </Collapse>

        <Divider />

        {/* Actions */}
        <MenuItem
          onClick={() => setDeleteDialog(true)}
          sx={{
            color: "error.main",
            justifyContent: "center",
            my: 0.5,
          }}
          disabled={fileList.length === 0 || loading}
        >
          <DeleteIcon sx={{ mr: 1 }} />
          <Typography variant="body2">Clear All</Typography>
        </MenuItem>
      </StyledMenu>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)} maxWidth="xs" fullWidth>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete all {fileList.length} files? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={() => setDeleteDialog(false)} color="success" disabled={loading}>
            Cancel
          </VuiButton>
          <VuiButton
            onClick={handleClearAll}
            color="error"
            variant="contained"
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Delete All
          </VuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileDropdown;
