import React from "react";
import VuiBox from "components/VuiBox";
import { CircularProgress, Grid } from "@mui/material";
import Pie from "./Pie";
import VuiTypography from "components/VuiTypography";

function Charts({ chartsData, loading, error }) {
  return (
    <VuiBox p={3}>
      {loading ? (
        <VuiBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="20rem"
        >
          <CircularProgress color="primary" size={110} />
          <VuiTypography variant="body1" color="white" mt={5}>
            Loading...
          </VuiTypography>
        </VuiBox>
      ) : error ? (
        <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="300px">
          <VuiTypography color="error" variant="h6" textAlign="center">
            Failed to load data. Please try again.
          </VuiTypography>
        </VuiBox>
      ) : (
        <Grid container spacing={2}>
          {chartsData.map((chart, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={chart.title}>
              <Pie title={chart.title} data={chart.data} options={{ labels: chart.labels }} />
            </Grid>
          ))}
        </Grid>
      )}
    </VuiBox>
  );
}

export default Charts;
