import React from "react";
import { Grid, Card } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Analytic1Table from "./components/Table/Analytics1Table";
import Stats from "./components/Stats/Stats";

function Analytics1() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            {/* Dashboard Title */}
            <Grid item xs={12}>
              <VuiTypography variant="h2" color="white" fontWeight="bold" mb="5px">
                Analytics-1
              </VuiTypography>
            </Grid>

            {/* Analytic1 Table */}
            <Grid item xs={12}>
              <Card>
                <VuiBox p={2}>
                  <Analytic1Table />
                </VuiBox>
              </Card>
            </Grid>
            <Stats />
          </Grid>
        </VuiBox>
      </VuiBox>

      {/* Footer */}
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics1;
