import { Grid } from "@mui/material";
import React from "react";
import InteractionCard from "../Cards/InteractionCard";
import VuiBox from "components/VuiBox";
import IndividualDropdowns from "../List/IndividualList";
import VuiTypography from "components/VuiTypography";
import Card from "@mui/material/Card";
import Pie from "../Chart/Pie";
import IntentTable from "../Table/IntentTable";
import FilterCard from "../Cards/FilterCard";

function Individual() {
  return (
    <Grid container spacing={2}>
      {/* Header Section */}
      <Grid item xs={12}>
        <VuiBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: { xs: "wrap", md: "nowrap" }, // Flex wrapping for smaller screens
          }}
        >
          <VuiTypography variant="h4" color="white" fontWeight="bold" mb="5px">
            Individual&apos;s Report
          </VuiTypography>
          <VuiBox>
            <IndividualDropdowns />
          </VuiBox>
        </VuiBox>
      </Grid>

      {/* Left Section */}
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <VuiBox padding={2} sx={{ backgroundColor: "#0f1635", borderRadius: "1rem" }}>
                <InteractionCard />
              </VuiBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <VuiBox padding={2}>
                <Pie title="Overall Sentiment" />
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {/* Right Section */}
      <Grid item xs={12} sm={12} md={8} lg={9}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <VuiBox padding={2}>
                <IntentTable />
              </VuiBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <VuiBox padding={2}>
                <FilterCard />
              </VuiBox>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Individual;
