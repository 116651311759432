import React from "react";
import { Grid } from "@mui/material"; // Using Material-UI Grid
import VuiBox from "components/VuiBox"; // Ensure this path is correct
import VuiTypography from "components/VuiTypography"; // Ensure this path is correct
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

function InteractionCard() {
  return (
    <Grid container spacing={2}>
      {/* Header Section */}
      <Grid item xs={12}>
        <VuiTypography variant="h6" color="white">
          Interaction Stats
        </VuiTypography>
      </Grid>

      {/* Individual Interaction Cards */}
      {[
        { title: "Talk Ratio", value: "60%", status: "Within Range", statusColor: "success" },
        {
          title: "Longest Monologue",
          value: "30:16 Mins",
          status: "Above Average",
          statusColor: "warning",
        },
        {
          title: "Longest Customer Story",
          value: "30:16 Mins",
          status: "Within Range",
          statusColor: "success",
        },
        { title: "Interaction", value: "60 Mins", status: "Within Range", statusColor: "success" },
      ].map((item, index) => (
        <Grid item xs={12} key={index}>
          <Card sx={{ backgroundColor: "#1e1e1e", color: "white" }}>
            <CardContent>
              <VuiTypography variant="h6" color="white">
                {item.title}
              </VuiTypography>
              <VuiBox flexWrap="noWrap" justifyContent="space-around" display="flex">
                <VuiTypography variant="h5" color="white">
                  {item.value}
                </VuiTypography>
                <VuiTypography variant="body2" color={item.statusColor}>
                  {item.status}
                </VuiTypography>
              </VuiBox>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default InteractionCard;
