import {
  Card,
  TextField,
  Avatar,
  Tab,
  ListItemText,
  ListItem,
  ListItemIcon,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";

export const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  background: theme.palette.background.paper,
  boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
}));

export const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    marginBottom: 0,
  },
}));

export const PasswordRequirement = ({ satisfied, text }) => (
  <ListItem dense>
    <ListItemIcon sx={{ minWidth: 36 }}>
      {satisfied ? (
        <CheckIcon color="success" fontSize="small" />
      ) : (
        <CloseIcon color="error" fontSize="small" />
      )}
    </ListItemIcon>
    <ListItemText
      primary={text}
      primaryTypographyProps={{
        variant: "body2",
        color: satisfied ? "success.main" : "error.main",
      }}
    />
  </ListItem>
);
export const StyledTab = styled(Tab)(({ theme }) => ({
  color: "#A49A8D !important",
  "&.Mui-selected": {
    color: "#ffff !important",
  },
  // "&.MuiTab-root": {
  //   color: "#A49A8D !important",
  // },
  minHeight: 48,
  padding: "12px 16px",
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.09)" : "rgba(255, 255, 255, 0.9)",
    "&:hover": {
      backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.13)" : "white",
    },
    "& fieldset": {
      borderColor:
        theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.23)" : "rgba(0, 0, 0, 0.23)",
    },
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)",
  },
  "& .MuiOutlinedInput-input": {
    color: theme.palette.mode === "dark" ? "#fff" : "inherit",
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 150,
  height: 150,
  marginBottom: theme.spacing(2),
  border: `4px solid ${theme.palette.divider}`,
}));
