// components/ErrorState.js
import React from "react";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

const ErrorState = ({ error, onRetry }) => (
  <VuiBox
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    minHeight="calc(100vh - 200px)"
  >
    <VuiTypography variant="h5" color="error" gutterBottom>
      {error}
    </VuiTypography>
    <VuiButton variant="contained" color="primary" onClick={onRetry} sx={{ mt: 2 }}>
      Retry
    </VuiButton>
  </VuiBox>
);

export default ErrorState;
