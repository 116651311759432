export const ENDPOINTS = {
  SSO_GOOGLE_LOGIN: "/googlesso/",
  SSO_OUTLOOK_LOGIN: "/outlooksso/",
  LOGOUT: "/logout/",
  REFRESH_TOKEN: "/token/refresh/",
  JOIN_MEETING: "/join-meeting/",
  GET_MEETINGS: "/get_meetings/",
  OUTLOOK_MEETINGS: "/outlook-meetings/",
  SCHEDULER: "/open_calendar/",
  OUTLOOK_SCHEDULER: "/outlook-calendar/",
  UPLOAD: "/upload/",
  QUERY: "/query/",
  PAST_MEETING: "/get-past-meetings/",
  JOIN_WITH_JOTTER: "/update-join-with-jotter/",
  ANALYTICS1: "/user/meetings/analytics-1/",
  ANALYTICS2: "/user/meetings/analytics-2/",
  ACTION_ITEM_UPDATE: "/meetings/action-item/",
  USER_QUOTE: "/user/meeting-quota/",
  ANALYTICS1_STATS: "/user/analytics-1/stats/",
  FILE_LIST: "/user/files/list/",
  DELETE_FILE_LIST: "/user/files/delete/",
};
