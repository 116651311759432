import React from "react";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import jsPDF from "jspdf";
import "jspdf-autotable";

function DownloadReport({ data }) {
  const handleDownload = () => {
    if (!data || data.length === 0) {
      console.warn("No data to download");
      return;
    }

    const doc = new jsPDF();
    doc.text("Meeting Report", 14, 10);

    const tableColumn = [
      "Meeting Subject",
      "Action Item",
      "Date Opened",
      "Due Date",
      "Responsible",
      "Priority",
      "Status",
    ];

    const tableRows = data.map((row) => [
      row.meeting_subject || "N/A",
      row.action_item || "N/A",
      row.date_opened || "N/A",
      row.due_date || "N/A",
      row.responsible || "N/A",
      row.priority || "N/A",
      row.status || "N/A",
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });

    doc.save("meeting_report.pdf");
  };

  return (
    <VuiBox>
      <VuiButton
        variant="outlined"
        color="info"
        onClick={handleDownload}
        sx={{
          m: 2,
          circular: true,
          color: "white",
          width: "10rem",
          height: "3rem",
          textAlign: "center",
          boxShadow: 2,
          "&:hover": {
            boxShadow: 4,
          },
        }}
      >
        Download Report
      </VuiButton>
    </VuiBox>
  );
}

export default DownloadReport;
