import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { VisionUIControllerProvider } from "context";
import { Provider } from "react-redux";
import { store } from "shared/store/Store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import config from "shared/Config/config";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "shared/Config/outlookAuthConfig";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <BrowserRouter>
          <VisionUIControllerProvider>
            <App />
          </VisionUIControllerProvider>
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  </GoogleOAuthProvider>
);
