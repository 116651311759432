import React, { useState, forwardRef, useImperativeHandle } from "react";
import MADialog from "components/MADialog/MAdialog";
const Meeting = forwardRef(({ title }, ref) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openDialog: () => setDialogOpen(true),
    closeDialog: () => setDialogOpen(false),
  }));

  const handleConnect = (data) => {
    // console.log("Connected to:", data); // Handle the URL and title connection
    setDialogOpen(false); // Close the dialog after connecting
  };

  return (
    <MADialog
      title={title}
      open={dialogOpen}
      onClose={() => setDialogOpen(false)} // Close the dialog
      onConnect={handleConnect}
    />
  );
});

export default Meeting;
