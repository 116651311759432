import React from "react";
import useApi from "shared/hooks/UseApi";
import { scheduler } from "shared/services/ApiService/ApiService";
import VuiButton from "components/VuiButton";
import { buttonStyles } from "../Styles";
import VuiBox from "components/VuiBox";

const SchedulerButton = () => {
  const { callApi } = useApi(scheduler);

  const handleSchedulerClick = async () => {
    try {
      const response = await callApi(scheduler);
      // console.log(response);
      if (response && response.data.calendar_url) {
        window.open(response.data.calendar_url, "_blank");
      }
    } catch (error) {
      console.error("Error calling Scheduler API:", error);
    }
  };

  return (
    <VuiBox>
      <VuiButton onClick={handleSchedulerClick} {...buttonStyles}>
        Scheduler
      </VuiButton>
    </VuiBox>
  );
};

export default SchedulerButton;
