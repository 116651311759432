import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Icon from "@mui/material/Icon";
import GradientBorder from "examples/GradientBorder";
import IconButton from "@mui/material/IconButton";
import rgba from "assets/theme/functions/rgba";
import borders from "assets/theme/base/borders";
import Stack from "@mui/material/Stack";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgSignIn from "assets/images/signInImage.png";
import { FaGoogle, FaMicrosoft } from "react-icons/fa";
import useAuth from "shared/hooks/UseAuth";
import { useGoogleLogin } from "@react-oauth/google";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setAuthProvider } from "shared/Slices/AuthSlice";
import { useDispatch } from "react-redux";
import config from "shared/Config/config";

function SignIn() {
  const {
    loading,
    error,
    handleGoogleLoginSuccess,
    handleGoogleLoginError,
    handleOutlookLogin,
    isAuthenticated,
    authProvider,
  } = useAuth();

  const history = useHistory();
  const dispatch = useDispatch();
  // Use Redux to get the authProvider state
  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginError,
    scope: [
      "openid", // Identity
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/calendar.events.readonly", // Calendar Permissions
      "https://www.googleapis.com/auth/calendar.readonly",
      "https://www.googleapis.com/auth/calendar",
    ].join(" "),
    flow: "auth-code",
  });

  // Handle Microsoft callback to extract the code from the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    // console.log("code", code);
    window.history.replaceState({}, document.title, window.location.pathname);
    // Only handle the code if the Microsoft login was initiated
    // console.log(authProvider, code?.length > 0);
    if (authProvider === "outlook" && code?.length > 0) {
      handleOutlookLogin(code);
    }
  }, [authProvider, handleOutlookLogin]);

  useEffect(() => {
    if (isAuthenticated) {
      // Redirect to the Outlook login URL after successful authentication
      history.push("/home");
    }
  }, [isAuthenticated]);

  const handleMicrosoftLogin = () => {
    dispatch(setAuthProvider("outlook"));
    window.location.href = `${config.BASE_URL}/login/outlook/`;
  };

  return (
    <CoverLayout
      title="Nice to see you!"
      color="white"
      description="Enter your email and password to sign in"
      premotto="Revolutionize the way you handle meetings"
      motto="MEETARISE"
      image={bgSignIn}
      cardContent
    >
      <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.focus,
          })}
        >
          <VuiTypography
            color="white"
            fontWeight="bold"
            textAlign="center"
            mb="24px"
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            Sign In with
          </VuiTypography>
          <Stack justifyContent="center" alignItems="center" direction="row" spacing={2}>
            <GradientBorder borderRadius="xl">
              <IconButton
                onClick={googleLogin}
                disabled={loading}
                sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
                  borderRadius: borderRadius.xl,
                  padding: "25px",
                  backgroundColor: secondary.focus,
                  "&:hover": {
                    backgroundColor: rgba(secondary.focus, 0.9),
                  },
                })}
              >
                <Icon
                  as={FaGoogle}
                  sx={({ palette: { white } }) => ({
                    color: white.focus,
                    width: "30px",
                    height: "30px",
                  })}
                />
              </IconButton>
            </GradientBorder>
            <GradientBorder borderRadius="xl">
              <IconButton
                onClick={handleMicrosoftLogin}
                disabled={loading}
                sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
                  borderRadius: borderRadius.xl,
                  padding: "25px",
                  backgroundColor: secondary.focus,
                  "&:hover": {
                    backgroundColor: rgba(secondary.focus, 0.9),
                  },
                })}
              >
                <Icon
                  as={FaMicrosoft}
                  sx={({ palette: { white } }) => ({
                    color: white.focus,
                    width: "30px",
                    height: "30px",
                  })}
                />
              </IconButton>
            </GradientBorder>
          </Stack>
          {error && (
            <VuiTypography color="error" textAlign="center" mb="14px">
              {error}
            </VuiTypography>
          )}
          {loading && (
            <VuiTypography color="white" textAlign="center" mb="14px">
              Signing in...
            </VuiTypography>
          )}
          <VuiBox mt={3} textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              Don&apos;t have an account?{" "}
              <VuiTypography
                component={Link}
                to="/authentication/sign-up"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                Sign up
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </CoverLayout>
  );
}

export default SignIn;

///******************************/////////////
// import React, { useState, useEffect } from "react";
// import useApi from "shared/hooks/UseApi";
// import { ssoOutlooklogin } from "shared/services/ApiService/ApiService";

// const SignIn = () => {
//   const { callApi } = useApi(ssoOutlooklogin);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const code = urlParams.get("code");
//     console.log("code", code);
//     if (code) {
//       handleAuthorizationCode(code);
//     }
//   }, []);

//   const handleSignIn = () => {
//     setIsLoading(true);
//     setError(null);
//     // Redirect to your Django backend's Outlook login URL
//     window.location.href = "http://localhost:8000/api/login/outlook/";
//   };

//   const handleAuthorizationCode = async (code) => {
//     try {
//       setIsLoading(true);
//       setError(null);
//       // Remove the code from the URL

//       window.history.replaceState({}, document.title, window.location.pathname);

//       // Send the code to your backend
//       const response = await callApi(code);
//       console.log("response", response);

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.error || "Network response was not ok");
//       }

//       const data = await response.json();
//       console.log("data", data); // Debugging output

//       if (data.access_token) {
//         setIsAuthenticated(true);
//         // Update your app state or context here if necessary
//       } else {
//         setError("Authentication failed");
//       }
//     } catch (err) {
//       setError(err.message || "An error occurred during sign-in");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   if (isAuthenticated) {
//     return <div>You are signed in!</div>;
//   }

//   return (
//     <button onClick={handleSignIn} disabled={isLoading}>
//       Sign in with Outlook
//     </button>
//   );
// };

// export default SignIn;
