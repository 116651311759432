import React from "react";
import { Grid, Card, CardHeader } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import { FileUpload } from "./components/Upload/FileUpload";
import { FolderUpload } from "./components/Upload/FolderUpload";
import { SharedDriveUpload } from "./components/Upload/SharedDriveUpload";
import { AudioVideoUpload } from "./components/Upload/AudioVideoUpload";
import ChatBox from "./components/Right/Right";

function Querent() {
  return (
    <DashboardLayout>
      <VuiBox
        py={3}
        sx={{
          minHeight: "100vh", // Set minimum height to 100% of the viewport
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DashboardNavbar />
        <VuiBox flexGrow={1} py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <VuiTypography variant="h2" color="white" fontWeight="bold" mb="5px">
                Querent Dashboard
              </VuiTypography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5} xl={5}>
              <Card>
                <VuiBox p={2}>
                  <CardHeader
                    title={
                      <VuiTypography variant="h5" color="white">
                        Info Access
                      </VuiTypography>
                    }
                  />
                  <VuiBox display="flex" flexWrap="wrap" justifyContent="space-between" gap={6}>
                    <FileUpload />
                    <FolderUpload />
                    <SharedDriveUpload />
                    <AudioVideoUpload />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={7} xl={7}>
              <Card sx={{ height: "100%" }}>
                <VuiBox p={2} sx={{ height: "100%" }}>
                  <ChatBox />
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>
        <Footer />
      </VuiBox>
    </DashboardLayout>
  );
}

export default Querent;
