import React from "react";
import { Grid, Card } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Analytics2Table from "./Components/Table";

function Analytics2() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox
        sx={{
          minHeight: "100vh",
        }}
        py={3}
      >
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            {/* Dashboard Title */}
            <Grid item xs={12}>
              <VuiTypography variant="h2" color="white" fontWeight="bold" mb="5px">
                Analytics-2
              </VuiTypography>
            </Grid>

            {/* Analytic1 Table */}
            <Grid item xs={12}>
              <Card>
                <VuiBox p={2}>
                  <Analytics2Table />
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>

      {/* Footer */}
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics2;
