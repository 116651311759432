// components/LoadingOverlay.js
import React from "react";
import { CircularProgress } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";

const LoadingOverlay = () => (
  <VuiBox
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 9999,
    }}
  >
    <CircularProgress size={48} sx={{ mb: 2 }} />
    <VuiTypography variant="h5" color="white">
      Loading your settings...
    </VuiTypography>
  </VuiBox>
);

export default LoadingOverlay;
