import React from "react";
import { Grid, Card } from "@mui/material";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import BrainiacTable from "./components/Table/Table";
import Analysis from "./components/Analysis/Analysis";

function Brainiac() {
  return (
    <DashboardLayout>
      {/* Navbar */}
      <DashboardNavbar />

      {/* Brainiac Dashboard Content */}
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            {/* Dashboard Title */}
            <Grid item xs={12}>
              <VuiTypography variant="h2" color="white" fontWeight="bold" mb="5px">
                Brainiac Dashboard
              </VuiTypography>
            </Grid>

            {/* Brainiac Table Section */}
            <Grid item xs={12} sm={12}>
              <Card>
                <VuiBox>
                  <BrainiacTable />
                </VuiBox>
              </Card>
            </Grid>

            {/* Report Section */}
            <Grid item xs={12} sm={12}>
              <Card>
                <VuiBox p={2}>
                  <VuiTypography variant="h5" color="white">
                    <Analysis />
                  </VuiTypography>
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>

      {/* Footer */}
      <Footer />
    </DashboardLayout>
  );
}

export default Brainiac;
