/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/** 
  All of the routes for the Vision UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Vision UI Dashboard React layouts
// import Profile from "layouts/Profile/Profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import SignOut from "layouts/authentication/sign-out/Signout";
import Jotter from "layouts/apps/Jotter/Jotter";
import Brainiac from "layouts/apps/Brainiac/Brainiac";
import Querent from "layouts/apps/Querent/Querent";
import Analytics1 from "layouts/apps/Analytics/Analytics1/Analytics1";
import Analytics2 from "layouts/apps/Analytics/Analytics2/Analytics2";
import Home from "layouts/Home/Home";
import Settings from "layouts/Settings/Settings";
// Vision UI Dashboard React icons
import { BsFillPersonCheckFill, BsFillPersonPlusFill, BsFillPersonXFill } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";

import { IoHome } from "react-icons/io5";
import {
  Assessment,
  BarChart,
  CloudUpload,
  SettingsApplications,
  TableChart,
} from "@mui/icons-material";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: "/home",
    icon: <IoHome size="15px" color="inherit" />,
    component: Home,
    noCollapse: true,
    requiresAuth: true,
  },
  {
    type: "collapse",
    name: "Jotter",
    key: "jotter",
    route: "/jotter",
    icon: <FaCalendarAlt size="15px" color="inherit" />,
    component: Jotter,
    noCollapse: true,
    requiresAuth: true,
  },
  {
    type: "collapse",
    name: "Querent",
    key: "querent",
    route: "/querent",
    icon: <CloudUpload size="15px" color="inherit" />,
    component: Querent,
    noCollapse: true,
    requiresAuth: true,
  },
  {
    type: "collapse",
    name: "Brainiac",
    key: "brainiac",
    route: "/brainiac",
    icon: <Assessment size="15px" color="inherit" />,
    component: Brainiac,
    noCollapse: true,
    requiresAuth: true,
  },
  { type: "title", title: "Analytics", key: "Analytics", requiresAuth: false },
  {
    type: "collapse",
    name: "Analytics-1",
    key: "analytics1",
    route: "/analytics1",
    icon: <BarChart size="15px" color="inherit" />,
    component: Analytics1,
    noCollapse: true,
    requiresAuth: true,
  },
  {
    type: "collapse",
    name: "Analytics-2",
    key: "analytics2",
    route: "/analytics2",
    icon: <TableChart size="15px" color="inherit" />,
    component: Analytics2,
    noCollapse: true,
    requiresAuth: true,
  },
  { type: "title", title: "Account Pages", key: "account-pages", requiresAuth: false },
  {
    type: "collapse",
    name: "Settings",
    key: "Settings",
    route: "/Settings",
    icon: <SettingsApplications size="15px" color="inherit" />,
    component: Settings,
    noCollapse: true,
    requiresAuth: false,
    show: true,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/profile",
  //   icon: <BsFillPersonFill size="15px" color="inherit" />,
  //   component: Profile,
  //   noCollapse: true,
  //   requiresAuth: false,
  //   show: true,
  // },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/",
    icon: <BsFillPersonCheckFill size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
    requiresAuth: false,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <BsFillPersonPlusFill size="15px" color="inherit" />,
    component: SignUp,
    noCollapse: true,
    requiresAuth: false,
  },
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    route: "/authentication/sign-out",
    icon: <BsFillPersonXFill size="15px" color="inherit" />,
    component: SignOut,
    noCollapse: true,
    requiresAuth: true,
  },
];

export default routes;
