const BASE_URL = process.env.REACT_APP_API_URL;

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
const MICROSOFT_TENANT_ID = process.env.REACT_APP_MICROSOFT_TENANT_ID;
const MICROSOFT_REDIRECT_URI = process.env.REACT_APP_MICROSOFT_REDIRECT_URI;

export default {
  BASE_URL,
  GOOGLE_CLIENT_ID,
  MICROSOFT_CLIENT_ID,
  MICROSOFT_TENANT_ID,
  MICROSOFT_REDIRECT_URI,
};
