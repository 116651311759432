import React, { useRef } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const ScrollbarWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100%",

  // Custom scrollbar styling for WebKit browsers
  "&::-webkit-scrollbar": {
    height: "0.7rem",
    width: "max-content",
  },

  "&::-webkit-scrollbar-track": {
    backgroundColor: "rgba(13, 17, 38, 0.6)", // Dark blue-ish background matching the dashboard
    borderRadius: "1rem",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#2563eb", // Primary blue color matching the "Join Meeting" buttons
    borderRadius: "1rem",
    "&:hover": {
      backgroundColor: "#1d4ed8", // Slightly darker blue on hover
    },
  },

  // Firefox scrollbar styling
  scrollbarWidth: "thin",
  scrollbarColor: "#2563eb rgba(13, 17, 38, 0.6)", // Thumb and track colors for Firefox

  overflowY: "auto",
  overflowX: "auto",
  scrollBehavior: "smooth",
}));

const MAScrollbar = ({ children, style = {}, onScroll = () => {}, ...props }) => {
  const scrollRef = useRef(null);

  // Custom scroll handler
  const handleScroll = (event) => {
    onScroll(event);
  };

  return (
    <ScrollbarWrapper
      ref={scrollRef}
      onScroll={handleScroll}
      style={{
        maxHeight: style.maxHeight || "100%",
        maxWidth: style.maxWidth || "100%",
        ...style,
      }}
      {...props}
    >
      {children}
    </ScrollbarWrapper>
  );
};

export default MAScrollbar;
