import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../Slices/AuthSlice";
import userReducer from "../Slices/userSlice";
import chatHistoryReducer from "../Slices/chatHistorySlice";
import meetingReducer from "../Slices/MeetingSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer, // You need to specify the slice name (e.g., 'auth')
    user: userReducer,
    chatHistory: chatHistoryReducer,
    meetings: meetingReducer,
  },
});
