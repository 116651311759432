import React, { Component } from "react";
import Chart from "react-apexcharts";

class LineChart extends Component {
  constructor(props) {
    super(props);

    // Set initial state based on props or default values
    this.state = {
      options: props.options || {
        chart: {
          id: "apexchart-line-example",
        },
        xaxis: {
          categories: props.categories || [], // Default to empty categories
        },
      },
      series: props.series || [], // Default to empty series
    };
  }

  render() {
    return (
      <Chart options={this.state.options} series={this.state.series} type="line" height="100%" />
    );
  }
}

export default LineChart;
