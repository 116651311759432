import PieChart from "examples/Charts/PieCharts/Piechart";
import React from "react";
import Pie from "../Chart/Pie";
import { Card, Grid } from "@mui/material";
import EmotionalAnalysisChart from "../Chart/Line";
import EngagementBarChart from "../Chart/bar";
import MeetingTable from "../Table/Table";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import Dropdowns from "../List/list";

function Symantic() {
  return (
    <Grid container spacing={2}>
      {/* Header Section */}
      <Grid item xs={12}>
        <VuiBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <VuiTypography variant="h4" color="white" fontWeight="bold" mb="5px">
            Symantic Report
          </VuiTypography>
          <VuiBox>
            <Dropdowns />
          </VuiBox>
        </VuiBox>
      </Grid>

      {/* Pie Chart - Overall Sentiment */}
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <Card>
          <VuiBox padding={2}>
            <Pie title="Overall Sentiment" />
          </VuiBox>
        </Card>
      </Grid>

      {/* Engagement Bar Chart */}
      <Grid item xs={12} sm={12} md={8} lg={9}>
        <Card>
          <VuiBox padding={2}>
            <EngagementBarChart />
          </VuiBox>
        </Card>
      </Grid>

      {/* Emotional Analysis Chart */}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Card>
          <VuiBox padding={2}>
            <EmotionalAnalysisChart />
          </VuiBox>
        </Card>
      </Grid>

      {/* Meeting Table */}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Card>
          <VuiBox padding={2}>
            <MeetingTable />
          </VuiBox>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Symantic;
