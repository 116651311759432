import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { FaGoogle, FaMicrosoft } from "react-icons/fa";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import GradientBorder from "examples/GradientBorder";
import rgba from "assets/theme/functions/rgba";
import borders from "assets/theme/base/borders";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgSignIn from "assets/images/signUpImage.png";
import useAuth from "shared/hooks/UseAuth";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGoogleLogin } from "@react-oauth/google";
import { setAuthProvider } from "shared/Slices/AuthSlice";
import { useDispatch } from "react-redux";
import config from "shared/Config/config";

function SignUp() {
  const {
    loading,
    error,
    handleGoogleLoginSuccess,
    handleGoogleLoginError,
    handleOutlookLogin,
    isAuthenticated,
    authProvider,
  } = useAuth();

  const history = useHistory();
  const dispatch = useDispatch();
  // Use Redux to get the authProvider state
  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: handleGoogleLoginError,
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events.readonly",
    flow: "auth-code",
  });

  // Handle Microsoft callback to extract the code from the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    // console.log("code", code);
    window.history.replaceState({}, document.title, window.location.pathname);
    // Only handle the code if the Microsoft login was initiated
    if (authProvider === "outlook" && code?.length > 0) {
      handleOutlookLogin(code);
    }
  }, [authProvider, handleOutlookLogin]);

  useEffect(() => {
    if (isAuthenticated) {
      // Redirect to the Outlook login URL after successful authentication
      history.push("/home");
    }
  }, [isAuthenticated]);

  const handleMicrosoftLogin = () => {
    dispatch(setAuthProvider("outlook"));
    window.location.href = `${config.BASE_URL}/login/outlook/`;
  };

  return (
    <CoverLayout
      title="Welcome!"
      color="white"
      description="Sign-up with your Google/Microsoft's account."
      image={bgSignIn}
      premotto="Revolutionize the way you handle meetings"
      motto="MEETARISE"
      cardContent
    >
      <GradientBorder borderRadius={borders.borderRadius.form} minWidth="100%" maxWidth="100%">
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.focus,
          })}
        >
          <VuiTypography
            color="white"
            fontWeight="bold"
            textAlign="center"
            mb="24px"
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            Register with
          </VuiTypography>
          <Stack mb="25px" justifyContent="center" alignItems="center" direction="row" spacing={2}>
            <GradientBorder borderRadius="xl">
              <IconButton
                onClick={googleLogin}
                disabled={loading}
                transition="all .25s ease"
                justify="center"
                align="center"
                bg="rgb(19,21,54)"
                borderradius="15px"
                sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
                  borderRadius: borderRadius.xl,
                  padding: "25px",
                  backgroundColor: secondary.focus,
                  "&:hover": {
                    backgroundColor: rgba(secondary.focus, 0.9),
                  },
                })}
              >
                <Icon
                  as={FaGoogle}
                  w="30px"
                  h="30px"
                  sx={({ palette: { white } }) => ({
                    color: white.focus,
                  })}
                />
              </IconButton>
            </GradientBorder>
            <GradientBorder borderRadius="xl">
              <IconButton
                onClick={handleMicrosoftLogin}
                disabled={loading}
                transition="all .25s ease"
                justify="center"
                align="center"
                bg="rgb(19,21,54)"
                borderradius="15px"
                sx={({ palette: { secondary }, borders: { borderRadius } }) => ({
                  borderRadius: borderRadius.xl,
                  padding: "25px",
                  backgroundColor: secondary.focus,
                  "&:hover": {
                    backgroundColor: rgba(secondary.focus, 0.9),
                  },
                })}
              >
                <Icon
                  as={FaMicrosoft}
                  w="30px"
                  h="30px"
                  sx={({ palette: { white } }) => ({
                    color: white.focus,
                  })}
                />
              </IconButton>
            </GradientBorder>
          </Stack>
          {error && (
            <VuiTypography color="error" textAlign="center" mb="14px">
              {error}
            </VuiTypography>
          )}
          {loading && (
            <VuiTypography color="white" textAlign="center" mb="14px">
              Signing up...
            </VuiTypography>
          )}
          <VuiBox mt={3} textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              Already have an account?{" "}
              <VuiTypography
                component={Link}
                to="/"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                Sign in
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </CoverLayout>
  );
}

export default SignUp;
