import React, { useEffect, useMemo } from "react";
import VuiBox from "components/VuiBox";
import DonutChart from "examples/Charts/DonutChart/DonutChart";
import PropTypes from "prop-types";
import VuiButton from "components/VuiButton";
import useChartError from "shared/hooks/UseCharError";

function Donut({ title = "Default Donut Chart Title", data = [89, 56] }) {
  const chartOptions = useMemo(
    () => ({
      title: {
        text: title,
        align: "left",
        style: {
          color: "white",
          fontSize: "18px",
          fontWeight: "bold",
        },
      },
      labels: ["Spent", "Available"],
      legend: {
        position: "bottom",
        labels: {
          colors: "white",
        },
      },
    }),
    [title]
  );

  const { error, resetError, updateChart } = useChartError(chartOptions, data, "donut");

  useEffect(() => {
    updateChart(chartOptions, data, "donut");
  }, [chartOptions, data, updateChart]);

  if (error) {
    return (
      <VuiBox sx={{ height: "15rem", color: "white" }} p={2}>
        <h3>Chart Error</h3>
        <p aria-live="assertive">{error.message}</p>
        <VuiButton
          onClick={resetError}
          variant="contained"
          color="info"
          sx={{
            mt: 5,
            color: "white",
            circular: true,
            cursor: "pointer",
          }}
        >
          Try Again
        </VuiButton>
      </VuiBox>
    );
  }

  return (
    <VuiBox color="white" sx={{ height: "15rem" }}>
      <DonutChart options={chartOptions} series={data} />
    </VuiBox>
  );
}

Donut.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.number),
};

export default Donut;
