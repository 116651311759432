import React from "react";
import { Grid, Button, CircularProgress } from "@mui/material";
import { StyledTextField } from "../../Styles";

const UserForm = ({ formData, onInputChange, loading, onSave, errors }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StyledTextField
          fullWidth
          name="name"
          label="Full Name"
          value={formData.name}
          onChange={(e) => onInputChange(e.target.name, e.target.value)}
          error={Boolean(errors.name)}
          helperText={errors.name}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          fullWidth
          name="email"
          label="Email Address"
          type="email"
          value={formData.email}
          onChange={(e) => onInputChange(e.target.name, e.target.value)}
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          fullWidth
          name="phone"
          label="Phone Number"
          value={formData.phone}
          onChange={(e) => onInputChange(e.target.name, e.target.value)}
          error={Boolean(errors.phone)}
          helperText={errors.phone}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          fullWidth
          name="bio"
          label="Bio"
          multiline
          rows={4}
          value={formData.bio}
          onChange={(e) => onInputChange(e.target.name, e.target.value)}
          placeholder="Tell us about yourself..."
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          onClick={onSave}
          disabled={loading}
          sx={{
            minWidth: 200,
            height: 48,
          }}
        >
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserForm;
