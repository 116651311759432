import React, { useEffect, useMemo } from "react";
import LineChart from "examples/Charts/LineCharts/LineChart";
import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import useChartError from "shared/hooks/UseCharError";

function Line({ title, data, options }) {
  const defaultData = useMemo(
    () => [
      {
        name: "Mobile apps",
        data: [500, 250, 300, 220, 500, 250, 300, 230, 300, 350, 250, 400],
      },
      {
        name: "Websites",
        data: [200, 230, 300, 350, 370, 420, 550, 350, 400, 500, 330, 550],
      },
    ],
    []
  );

  const defaultOptions = useMemo(
    () => ({
      title: {
        text: title || "Default Title",
        align: "left",
        style: {
          color: "white",
          fontSize: "18px",
          fontWeight: "bold",
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        theme: "dark",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "category",
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "10px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "10px",
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 5,
        borderColor: "#56577A",
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0,
          inverseColors: true,
          opacityFrom: 0.8,
          opacityTo: 0,
          stops: [],
        },
        colors: ["#0075FF", "#2CD9FF"],
      },
      colors: ["#0075FF", "#2CD9FF"],
    }),
    [title]
  );

  const mergedOptions = useMemo(
    () => ({
      ...defaultOptions,
      ...options,
      title: {
        ...defaultOptions.title,
        ...(options?.title || {}),
        text: title || defaultOptions.title.text,
      },
    }),
    [defaultOptions, options, title]
  );

  const chartData = data || defaultData;
  const { error, resetError, updateChart } = useChartError(mergedOptions, chartData, "line");

  useEffect(() => {
    updateChart(mergedOptions, chartData, "line");
  }, [mergedOptions, chartData, updateChart]);

  if (error) {
    return (
      <VuiBox sx={{ height: "20rem", color: "white" }} p={2}>
        <h3>Chart Error</h3>
        <p aria-live="assertive">{error.message}</p>
        <VuiButton
          onClick={resetError}
          variant="contained"
          color="info"
          sx={{
            mt: 5,
            color: "white",
            circular: true,
            cursor: "pointer",
          }}
        >
          Try Again
        </VuiButton>
      </VuiBox>
    );
  }

  return (
    <VuiBox sx={{ height: "20rem" }}>
      <LineChart options={mergedOptions} series={chartData} />
    </VuiBox>
  );
}

Line.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ),
  options: PropTypes.object,
};

export default Line;
