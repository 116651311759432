// Reusable Card Component
import { Card, CardContent } from "@mui/material"; // Assuming you still use Material-UI Grid
import VuiBox from "components/VuiBox"; // Ensure this path is correct
import VuiTypography from "components/VuiTypography"; // Ensure this path is correct

export const InfoCard = ({ title, value, percentage }) => (
  <Card>
    <CardContent>
      <VuiTypography variant="h6" color="white">
        {title}
      </VuiTypography>
      <VuiBox display="flex" flexWrap="wrap" justifyContent="space-around">
        <VuiTypography variant="h6" color="white">
          {value}
        </VuiTypography>
        <VuiTypography variant="body2" color="success">
          {percentage}
        </VuiTypography>
      </VuiBox>
    </CardContent>
  </Card>
);
