import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import { PhoneAndroid as PhoneIcon } from "@mui/icons-material";
import { StyledSection } from "../../Styles";

const SessionTimeout = ({ sessionTimeout, onChange }) => {
  return (
    <StyledSection>
      <Box display="flex" alignItems="center" mb={2}>
        <PhoneIcon sx={{ mr: 1, color: "primary.main" }} />
        <Typography variant="h6">Session Timeout</Typography>
      </Box>
      <FormControl fullWidth>
        <InputLabel id="session-timeout-label">Session Timeout (minutes)</InputLabel>
        <Select
          labelId="session-timeout-label"
          name="sessionTimeout"
          value={sessionTimeout}
          onChange={onChange}
        >
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={120}>120</MenuItem>
        </Select>
      </FormControl>
      <Typography variant="body2" color="text.secondary" mt={1}>
        Select how long your session can remain active before timing out.
      </Typography>
    </StyledSection>
  );
};

export default SessionTimeout;
