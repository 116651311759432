import React from "react";
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMore, Security } from "@mui/icons-material";
import VuiBox from "components/VuiBox";

const SecuritySettings = ({ securitySettings, handleChange }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="security-settings-content"
        id="security-settings-header"
      >
        <VuiBox sx={{ display: "flex", alignItems: "center" }}>
          <Security sx={{ mr: 2 }} />
          <Typography variant="h6">Security Settings</Typography>
        </VuiBox>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={securitySettings.twoFactorAuth}
                  onChange={handleChange("twoFactorAuth")}
                  color="primary"
                />
              }
              label="Two-Factor Authentication"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Password Expiry (days)"
              type="number"
              variant="outlined"
              value={securitySettings.passwordExpiry}
              onChange={handleChange("passwordExpiry")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Session Timeout (minutes)"
              type="number"
              variant="outlined"
              value={securitySettings.sessionTimeout}
              onChange={handleChange("sessionTimeout")}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SecuritySettings;
