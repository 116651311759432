import React, { useEffect } from "react";
import PropTypes from "prop-types";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "examples/Tables/Table";
import useApi from "shared/hooks/UseApi";
import { getMeeting } from "shared/services/ApiService/ApiService";
function BrainiacTable() {
  const { data: meetings, error, loading, callApi } = useApi(getMeeting);
  // useEffect(() => {
  //   callApi(); // Fetch meetings when the component mounts
  // }, [callApi]);
  const response = {
    meetings: [
      {
        attendees: [
          {
            email: "prasad@meetarise.com",
            organizer: true,
            responseStatus: "accepted",
            self: true,
          },
          {
            email: "devanjan@meetarise.com",
            responseStatus: "needsAction",
          },
        ],
        creator: {
          email: "prasad@meetarise.com",
          self: true,
        },
        description: "",
        hangoutLink: "https://meet.google.com/myg-hzyg-ntg",
        location: "",
        organizer: {
          email: "prasad@meetarise.com",
          self: true,
        },
        start: "2024-09-22T02:30:00+05:30",
        summary: "test1",
      },
      {
        attendees: [
          {
            email: "prasad@meetarise.com",
            organizer: true,
            responseStatus: "accepted",
            self: true,
          },
          {
            email: "devanjan@meetarise.com",
            responseStatus: "needsAction",
          },
        ],
        creator: {
          email: "prasad@meetarise.com",
          self: true,
        },
        description: "",
        hangoutLink: "https://meet.google.com/nph-btbo-uab",
        location: "",
        organizer: {
          email: "prasad@meetarise.com",
          self: true,
        },
        start: "2024-09-22T05:00:00+05:30",
        summary: "test2",
      },
      {
        attendees: [
          {
            email: "prasad@meetarise.com",
            organizer: true,
            responseStatus: "accepted",
            self: true,
          },
          {
            email: "devanjan@meetarise.com",
            responseStatus: "needsAction",
          },
        ],
        creator: {
          email: "prasad@meetarise.com",
          self: true,
        },
        description: "",
        hangoutLink: "https://meet.google.com/iqj-kfme-vxm",
        location: "",
        organizer: {
          email: "prasad@meetarise.com",
          self: true,
        },
        start: "2024-09-22T07:00:00+05:30",
        summary: "test3",
      },
      {
        attendees: [
          {
            email: "prasad@meetarise.com",
            organizer: true,
            responseStatus: "accepted",
            self: true,
          },
          {
            email: "devanjan@meetarise.com",
            responseStatus: "needsAction",
          },
        ],
        creator: {
          email: "prasad@meetarise.com",
          self: true,
        },
        description: "",
        hangoutLink: "https://meet.google.com/reu-jykd-xxh",
        location: "",
        organizer: {
          email: "prasad@meetarise.com",
          self: true,
        },
        start: "2024-09-22T09:00:00+05:30",
        summary: "test4",
      },
      {
        attendees: [
          {
            email: "prasad@meetarise.com",
            organizer: true,
            responseStatus: "accepted",
            self: true,
          },
          {
            email: "devanjan@meetarise.com",
            responseStatus: "needsAction",
          },
        ],
        creator: {
          email: "prasad@meetarise.com",
          self: true,
        },
        description: "",
        hangoutLink: "https://meet.google.com/hbb-rvmu-kdb",
        location: "",
        organizer: {
          email: "prasad@meetarise.com",
          self: true,
        },
        start: "2024-09-22T10:30:00+05:30",
        summary: "test5",
      },
      {
        attendees: [
          {
            email: "prasad@meetarise.com",
            organizer: true,
            responseStatus: "accepted",
            self: true,
          },
          {
            email: "devanjan@meetarise.com",
            responseStatus: "needsAction",
          },
        ],
        creator: {
          email: "prasad@meetarise.com",
          self: true,
        },
        description: "",
        hangoutLink: "https://meet.google.com/txc-evcv-svh",
        location: "",
        organizer: {
          email: "prasad@meetarise.com",
          self: true,
        },
        start: "2024-09-22T12:30:00+05:30",
        summary: "test6",
      },
      {
        attendees: [
          {
            email: "devanjan@meetarise.com",
            responseStatus: "needsAction",
          },
          {
            email: "prasad@meetarise.com",
            organizer: true,
            responseStatus: "accepted",
            self: true,
          },
        ],
        creator: {
          email: "prasad@meetarise.com",
          self: true,
        },
        description: "",
        hangoutLink: "https://meet.google.com/pbp-ufzb-aus",
        location: "",
        organizer: {
          email: "prasad@meetarise.com",
          self: true,
        },
        start: "2024-09-22T14:30:00+05:30",
        summary: "test openciod",
      },
    ],
  };
  const meetingColumns = [
    { name: "Box Tick", align: "center" },
    { name: "Date", align: "center" },
    { name: "Time", align: "center" },
    { name: "Subject", align: "center" },
    { name: "Participants", align: "center" },
    { name: "Meeting Duration", align: "center" },
    { name: "Analysis Meeting", align: "center" },
    { name: "Meeting Sentiment %age", align: "center" },
    { name: "Status", align: "center" },
    { name: "Joining Mode", align: "center" },
  ];

  const formatMeetingData = (meetings) => {
    return meetings.map((meeting) => ({
      "Box Tick": "Done",
      Date: new Date(meeting.start).toLocaleDateString(),
      Time: new Date(meeting.start).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      Subject: meeting.summary,
      Participants: meeting.attendees.map((attendee) => attendee?.email).join(", "),
      "Meeting Duration": "1hr",
      "Analysis Meeting": "db",
      "Meeting Sentiment %age": "80%",
      Status: "Completed",
      "Joining Mode": meeting.hangoutLink ? "Jotter" : "In-Person",
    }));
  };

  const handleUpdate = async () => {
    // await callApi(); // Await the callApi to ensure data is fetched before updating state
  };

  // if (loading) {
  //   return <VuiTypography variant="body1">Loading meetings...</VuiTypography>;
  // }

  // if (error) {
  //   return (
  //     <VuiTypography variant="body1" color="error">
  //       Error fetching meetings: {error}
  //     </VuiTypography>
  //   );
  // }

  return (
    <>
      <VuiBox>
        {response.meetings && response.meetings.length > 0 ? (
          <Table columns={meetingColumns} rows={formatMeetingData(response.meetings)} />
        ) : (
          <VuiTypography variant="body1" color="error">
            No meetings found.
          </VuiTypography>
        )}
      </VuiBox>
    </>
  );
}

BrainiacTable.propTypes = {};

export default BrainiacTable;
