import React, { useState, useRef } from "react";
import { Dialog, DialogActions, DialogContent, IconButton } from "@mui/material";
import VuiButton from "components/VuiButton";
import VuiBox from "components/VuiBox";
import CloseIcon from "@mui/icons-material/Close";
import VuiTypography from "components/VuiTypography";

const buttonStyles = {
  variant: "contained",
  color: "primary",
  sx: {
    m: 2,
    circular: true,
    color: "white",
    width: "10rem",
    height: "3rem",
    textAlign: "center",
    boxShadow: 2,
    "&:hover": {
      boxShadow: 4,
    },
  },
};

const InPersonButton = () => {
  const [open, setOpen] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const audioChunks = useRef([]);

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => {
    setOpen(false);
    audioChunks.current = []; // Reset audio chunks when closing dialog
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { echoCancellation: true },
      });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = (event) => audioChunks.current.push(event.data);

      recorder.start();
      // console.log("Recording started");
    } catch (error) {
      // console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (!mediaRecorder) return;

    mediaRecorder.stop();
    mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
      audioChunks.current = []; // Reset audio chunks after stop

      const formData = new FormData();
      formData.append("file", audioBlob, "audio.wav");

      // Uncomment and replace with actual backend URL
      // try {
      //   await fetch("/api/upload/audio", {
      //     method: "POST",
      //     body: formData,
      //   });
      //   console.log("Audio uploaded successfully");
      // } catch (error) {
      //   console.error("Error uploading audio:", error);
      // }

      // console.log("Recording stopped and prepared for upload");
    };
  };

  return (
    <VuiBox>
      <VuiButton onClick={handleOpenDialog} {...buttonStyles}>
        In-Person
      </VuiButton>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#2D3250", // Dark background for dialog
            borderRadius: "10px",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <VuiBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
          <VuiTypography variant="h5" component="h2" color="white">
            {" "}
            {/* White text for contrast */}
            Audio Recording
          </VuiTypography>
          <IconButton color="white" onClick={handleCloseDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
        </VuiBox>

        <DialogContent>
          <VuiTypography variant="h6" color="white">
            {" "}
            {/* White text for contrast */}
            Record your audio and send it to the backend.
          </VuiTypography>
        </DialogContent>

        <DialogActions>
          <VuiButton onClick={startRecording} color="success">
            {" "}
            {/* Changed color to success for positive action */}
            Start Recording
          </VuiButton>
          <VuiButton onClick={stopRecording} color="error">
            {" "}
            {/* Changed color to error for stop action */}
            Stop Recording
          </VuiButton>
        </DialogActions>
      </Dialog>
    </VuiBox>
  );
};

export default InPersonButton;
